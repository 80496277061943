<mat-card>
    <mat-card-header>
        <div class="flex flex-row w-full">
            <div class="flex flex-col w-full">
                <div class="flex flex-row justify-center items-center gap-4">
                    <div class="font-semibold text-lg">Create new team mapping</div>
                </div>
            </div>
        </div>
    </mat-card-header>
    <mat-card-content>
        <div class="grid grid-cols-2 gap-3 p-10">
            <mat-form-field appearance="outline">
                <mat-label>Team Name</mat-label>
                <input matInput placeholder="Team Name" [(ngModel)]="teamName">
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Team Abbreviation</mat-label>
                <input matInput placeholder="Team Abbreviation" [(ngModel)]="teamAbbreviation" required>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Jersey Color</mat-label>
                <input matInput [(ngModel)]="jerseyColor">
                <ngx-colors matSuffix ngx-colors-trigger [(ngModel)]="jerseyColor" class="color-picker"></ngx-colors>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Home Team Flag</mat-label>
                <mat-select [(value)]="selectedTeamFlag" [(ngModel)]="teamBadge">
                    <mat-select-trigger>
                        <div class="flex flex-row items-center">
                            <div><img width="15" height="15" [src]='getSanitizedImageWithName(selectedTeamFlag)'
                                    style="margin-right: 10px"></div>
                            <div>{{(selectedTeamFlag ?? "").replace(".png", "")}}</div>
                        </div>
                    </mat-select-trigger>
                    <mat-option *ngFor="let flag of flags" [value]="flag.name">
                        <div class="flex flex-row items-center">
                            <div><img width="15" height="15" [src]='getSanitizedImage(flag.content)'
                                    style="margin-right: 10px">
                            </div>
                            <div>{{flag.name.replace(".png", "")}}</div>
                        </div>
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <button mat-raised-button (click)="save()" color="accent" class="float-right btn ">Save</button>
            <button mat-raised-button (click)="cancel()" color="warn" class="float-right btn ">Cancel</button>
        </div>
    </mat-card-content>
</mat-card>
