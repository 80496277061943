import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PlayerDTO } from 'src/app/dtos/PlayerDTO';

@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.css']
})
export class PlayerComponent {
  @Input() player!: PlayerDTO;
  @Input() playerForm!: FormGroup;
  @Input() index!: number;

  // playerName: String | undefined;
  // position: number | undefined;
  // looseHead: boolean | undefined = false;
  // tightHead: boolean | undefined = false;
  // hooker: boolean | undefined = false;
  // u19: boolean | undefined = false;

  constructor() { }

  ngOnInit(): void {
    // this.playerName = this.player?.fullName;
    // this.position = this.player?.position;
    // this.hooker = this.player?.hooker;
    // this.looseHead = this.player?.hooker;
    // this.tightHead = this.player?.tightHead;
    // this.u19 = this.player?.u19;
  }
}
