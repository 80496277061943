import {Component, OnInit} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatTableDataSource} from '@angular/material/table';
import {ActivatedRoute} from '@angular/router';
import {MatchDTO} from 'src/app/dtos/MatchDTO';
import {SanctionDTO} from 'src/app/dtos/SanctionDTO';
import {SanctionsService} from 'src/app/services/sanctions.service';
import {CitingReportDetailsComponent} from "../citing-report-details/citing-report-details.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {

  filterCriteria?: string;
  datasource: any;
  displayedColumns: string[] = ['team', 'player', 'isAdditionalReport', 'reportType', 'sanction', 'status', 'actions'];
  matchId = 0;
  sanctions: SanctionDTO[] = [];
  match?: MatchDTO;

  constructor(
    private _snackBar: MatSnackBar,
    private sanctionService: SanctionsService,
    private route: ActivatedRoute,
    public dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    const routeParams = this.route.snapshot.paramMap;
    if (routeParams.get("id") != null) {
      this.match = JSON.parse(localStorage.getItem("matchView")!);
      this.matchId = Number(routeParams.get("id"));
      this.getMatchSanctions(this.matchId);
    }
  }

  getTeamName(teamId: number) {
    if (this.match?.teamOne.id == teamId) {
      return this.match?.teamOne.name;
    } else {
      return this.match?.teamTwo.name;
    }
  }

  getTeamBadge(teamId: number) {
    if (this.match?.teamOne.id == teamId) {
      return this.match?.teamOne.teamBadge;
    } else {
      return this.match?.teamTwo.teamBadge;
    }
  }

  getPlayerName(playerId: number, teamId: number) {
    if (this.match?.teamOne.id == teamId) {
      const player = this.match!.teamOne!.players!.filter((s) => {
        return s.id == playerId;
      });
      return player[0].fullName;
    } else {
      const player = this.match!.teamTwo!.players!.filter((s) => {
        return s.id == playerId;
      });
      return player[0].fullName;
    }
  }

  getMatchSanctions(matchId: number) {
    this.sanctionService.getMatchSanctions(matchId).subscribe((data: SanctionDTO[]) => {
      this.sanctions = data;
      this.datasource = new MatTableDataSource(this.sanctions);
    });
  }

  getSanitizedImageWithName(imageName: String | undefined) {
    console.log("Getting badge for: " + imageName);
    return "assets/images/flags/" + imageName
  }

  getSanitizedCardTypeWithName(imageName: String | undefined) {
    if (imageName == "RED_CARD") {
      return "assets/images/logos/icon-red-card-enabled.png"
    }
    return "assets/images/logos/icon-yellow-card-enabled.png"
  }

  downloadSanctionReport(id: number, sanctionedPlayerName: String) {

    this.sanctionService.getSanctionReport(id).subscribe((response) => {
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(new Blob([response.body], {type: response.body.type}));

      const contentDisposition = response.headers.get('content-disposition');

      const fileName = sanctionedPlayerName + " Sanction Report.pdf";
      downloadLink.download = fileName;
      downloadLink.click();
    });
  }

  addNewReport(id: number): void {
    localStorage.setItem('addReportMatchId', id.toString());
    console.log(localStorage.getItem('addReportMatchId'))
    const dialogRef = this.dialog.open(CitingReportDetailsComponent);

    dialogRef.afterClosed().subscribe(result => {
      localStorage.removeItem('addReportMatchId');
      this.getMatchSanctions(this.matchId);
    });
  }

  editReport(id: number): void {
    localStorage.setItem('editReportId', id.toString());
    console.log(localStorage.getItem('editReportId'))
    const dialogRef = this.dialog.open(CitingReportDetailsComponent);

    dialogRef.afterClosed().subscribe(result => {
      localStorage.removeItem('editReportId');
      this.getMatchSanctions(this.matchId);
    });
  }
}
