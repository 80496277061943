<form [formGroup]="playerForm">
    <div class="grid grid-cols-6 gap-2 w-full">
        <div class="col-span-1">
            <mat-form-field appearance="outline" class="test-class">
                <mat-label>Jersey Number</mat-label>
                <input matInput formControlName="jerseyNumber" placeholder="Jersey Number" [tabIndex]="playerForm.value.position">
            </mat-form-field>
        </div>
        <div class="col-span-5">
            <mat-form-field appearance="outline" class="test-class">
                <mat-label>Player {{ playerForm.value.position }}</mat-label>
                <input matInput formControlName="playerName" placeholder="Player Name" [tabIndex]="playerForm.value.position">
                <mat-slide-toggle color="primary" matSuffix formControlName="looseHead" class="text-xs pr-3">LH</mat-slide-toggle>
                <mat-slide-toggle color="primary" matSuffix formControlName="hooker" class="text-xs pr-3">HC</mat-slide-toggle>
                <mat-slide-toggle color="primary" matSuffix formControlName="tightHead" class="text-xs pr-3">TH</mat-slide-toggle>
                <mat-slide-toggle color="primary" matSuffix formControlName="u19" class="text-xs pr-3">u19</mat-slide-toggle>
            </mat-form-field>
        </div>
    </div>
</form>