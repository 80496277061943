import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MatchesComponent} from './components/matches/matches.component';
import {ViewMatchComponent} from './components/view-match/view-match.component';
import {NewMatchComponent} from './components/new-match/new-match.component';
import {TournamentsComponent} from './components/tournaments/tournaments.component';
import {TournamentEmailsComponent} from './components/tournament-emails/tournament-emails.component';
import {TeamMappingsComponent} from './components/team-mappings/team-mappings.component';
import {TeamMappingComponent} from './components/team-mapping/team-mapping.component';
import {LoginComponent} from './components/login/login.component';
import {
  TournamentTeamMappingsComponent
} from "./components/tournament-team-mappings/tournament-team-mappings.component";
import {AuthGuard} from "./AuthGuard";
import {RedirectIfAuthenticatedGuard} from "./RedirectIfAuthenticatedGuard";
import {PortalUsersComponent} from "./components/portal-users/portal-users.component";
import {
  UserAssignedTournamentsComponent
} from "./components/user-assigned-tournaments/user-assigned-tournaments.component";

let routes: Routes = [];
routes = [
  {path: 'matches', component: MatchesComponent, canActivate: [AuthGuard]},
  {path: 'matches/:id', component: MatchesComponent, canActivate: [AuthGuard]},
  {path: 'match/:id', component: ViewMatchComponent, canActivate: [AuthGuard]},
  {path: 'new-match', component: NewMatchComponent, canActivate: [AuthGuard]},
  {path: 'tournaments', component: TournamentsComponent, canActivate: [AuthGuard]},
  {path: 'team-mappings', component: TeamMappingsComponent, canActivate: [AuthGuard]},
  {path: 'portal-users', component: PortalUsersComponent, canActivate: [AuthGuard]},
  {path: 'user-assigned-tournaments/:id', component: UserAssignedTournamentsComponent, canActivate: [AuthGuard]},
  {path: 'tournament-team-mappings/:id', component: TournamentTeamMappingsComponent, canActivate: [AuthGuard]},
  {path: 'team', component: TeamMappingComponent, canActivate: [AuthGuard]},
  {path: 'team/:id', component: TeamMappingComponent, canActivate: [AuthGuard]},
  {path: 'tournament-emails', component: TournamentEmailsComponent, canActivate: [AuthGuard]},
  {path: 'login', component: LoginComponent, canActivate: [RedirectIfAuthenticatedGuard]},
  {path: '**', component: LoginComponent, canActivate: [RedirectIfAuthenticatedGuard]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
