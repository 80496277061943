import {DatePipe} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {ControlContainer, FormBuilder, FormGroup, FormGroupDirective, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DomSanitizer} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';
import {NgxMaterialTimepickerTheme} from 'ngx-material-timepicker';
import {MatchDTO} from 'src/app/dtos/MatchDTO';
import {Flag} from 'src/app/interfaces/Flag';
import {MatchType} from 'src/app/interfaces/MatchType';
import {SubstitutionRules} from 'src/app/interfaces/SubstitutionRules';
import {MatchService} from 'src/app/services/MatchService';
import {MatChipEditedEvent, MatChipInputEvent} from "@angular/material/chips";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {TournamentsResponseDTO} from "../../dtos/TournamentsResponseDTO";
import {MatTableDataSource} from "@angular/material/table";
import {Tournament} from "../../interfaces/Tournament";
import {TournamentService} from "../../services/tournament.service";

@Component({
  selector: 'app-match-details',
  templateUrl: './match-details.component.html',
  styleUrls: ['./match-details.component.css'],
  viewProviders: [
    {provide: ControlContainer, useExisting: FormGroupDirective}
  ]
})
export class MatchDetailsComponent implements OnInit {

  form: FormGroup | undefined;
  matchDetailsSubForm!: FormGroup;
  match?: MatchDTO;
  flags?: Flag[];
  tournaments?: Tournament[];
  selectedHomeTeamFlag: String | undefined;
  selectedVisitingTeamFlag: String | undefined;

  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  matchTypes: MatchType[] = [
    {value: 'SEVEN_A_SIDE', viewValue: 'Seven a side'},
    {value: 'FIFTEEN_A_SIDE', viewValue: 'Fifteen a side'}
  ];

  subRules: SubstitutionRules[] = [
    {value: 'NORMAL', viewValue: 'Normal'},
    {value: 'ROLLING_SUBS', viewValue: 'Rolling Subs'}
  ];

  darkTheme: NgxMaterialTimepickerTheme = {
    container: {
      bodyBackgroundColor: '#093F8B',
      buttonColor: '#58be47',
    },
    dial: {
      dialBackgroundColor: '#FFFFFF',
      dialActiveColor: "#093F8B",
      dialInactiveColor: "#D3D3D3"
    },
    clockFace: {
      clockFaceBackgroundColor: '#FFFFFF',
      clockHandColor: '#58be47',
      clockFaceTimeInactiveColor: '#093F8B'
    }
  };

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private matchService: MatchService,
    private tournamentService: TournamentService,
    public datepipe: DatePipe,
    private _snackBar: MatSnackBar,
    private _sanitizer: DomSanitizer,
    private ctrlContainer: FormGroupDirective) {
  }

  ngOnInit(): void {
    this.form = this.ctrlContainer.form;
    this.matchDetailsSubForm = this.fb.group({
      homeTeam: ['', Validators.required],
      visitingTeam: ['', Validators.required],
      tournament: ['', Validators.required],
      venue: ['', Validators.required],
      matchDate: ['', Validators.required],
      matchTime: ['', Validators.required],
      homeTeamColor: ['', Validators.required],
      visitingTeamColor: ['', Validators.required],
      country: ['', Validators.required],
      conditions: ['', Validators.required],
      crowdSize: ['', Validators.required],
      teamSize: ['', Validators.required],
      matchType: ['', Validators.required],
      substitutionRule: ['', Validators.required],
      numberOfSubs: ['', Validators.required],
      homeTeamBadge: [''],
      visitingTeamBadge: ['']
    });

    this.matchService.getFlags().subscribe((data: Flag[]) => {
      this.flags = data
    }, (error => {
      console.error("Error calling service: " + JSON.stringify(error));
      this.handleError(error, "Unable to get flags");
    }));

    this.matchDetailsSubForm.patchValue({
      homeTeamColor: "#228B22",
      visitingTeamColor: "#FF0000"
    });

    // if (this.readOnly) {
    //   this.matchDetailsSubForm.disable();
    // }
    this.form.addControl("matchDetails", this.matchDetailsSubForm);

    const routeParams = this.route.snapshot.paramMap;
    console.log("MATCH COMPONENT " + routeParams.get("matchId"));
    if (routeParams.get("id") != null) {
      console.log("restoring form values...");
      this.match = JSON.parse(localStorage.getItem("matchView")!);
      console.log(this.datepipe.transform(this.match?.matchDate, 'HH:mm'));
      this.matchDetailsSubForm.patchValue({
        homeTeam: this.match?.teamOne.name,
        visitingTeam: this.match?.teamTwo.name,
        tournament: this.match?.tournament,
        venue: this.match?.stadium,
        matchDate: this.match?.matchDate,
        homeTeamColor: this.match?.teamOne.jerseyColor,
        visitingTeamColor: this.match?.teamTwo.jerseyColor,
        country: this.match?.country,
        conditions: this.match?.conditions,
        crowdSize: this.match?.crowdSize,
        matchTime: this.datepipe.transform(this.match?.matchDate, 'HH:mm'),
        matchType: this.match?.matchType,
        teamSize: this.match?.numberOfPlayers,
        substitutionRule: this.match?.substitutionRule,
        numberOfSubs: this.match?.matchConfiguration.numberOfSubstitutions,
        homeTeamBadge: this.match!.teamOne.teamBadge,
        visitingTeamBadge: this.match!.teamTwo.teamBadge
      })
      this.selectedHomeTeamFlag = this.match?.teamOne.teamBadge
      this.selectedVisitingTeamFlag = this.match?.teamTwo.teamBadge
    }

    this.tournamentService.getTournaments().subscribe((data: TournamentsResponseDTO) => {
      this.tournaments = data.tournaments;

      if (this.match != null) {
        let selectedTournament = this.tournaments?.find(tournament => tournament.id === this.match?.tournament.id);

        this.matchDetailsSubForm.patchValue({
          tournament: selectedTournament
        })
      }
    }, (error => {
      console.error("Error calling service: " + JSON.stringify(error));
      this.handleError(error, "Unable to get tournaments");
    }));
  }

  getSanitizedImage(content: String | undefined) {
    return this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,'
      + content);
  }

  getSanitizedImageWithName(imageName: String | undefined) {
    return "assets/images/flags/" + imageName
  }

  handleError(error: any, errorMessage: string): void {
    this._snackBar.open(errorMessage, 'OK', {
      horizontalPosition: "center",
      verticalPosition: "top",
    });
  }
}
