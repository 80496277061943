<div class="bg-white shadow-lg rounded-lg">
  <div class="flex items-center justify-between p-4 border-b bg-[#EFF0F1]">
    <span class="text-lg font-semibold" style="color: #19194B">Citing Commissioner Report</span>

    <div class="space-x-2">
      <button mat-raised-button style="border-radius: 10px;" (click)="cancel()">Cancel</button>
      <button mat-raised-button style="background-color: #00917B; color: white; border-radius: 10px;"
              (click)="save(false)">Save Draft
      </button>
      <button mat-raised-button style="background-color: #19194B; color: white; border-radius: 10px;"
              (click)="save(true)">Submit
      </button>
    </div>
  </div>

  <div class="p-4" style="overflow: auto; max-height: 700px; min-width: 1000px">
    <form [formGroup]="form" style="color: #19194B">

      <div class="flex-1 text-left pb-4" style="font-size: 14px; font-weight: bold">
        <p style="font-size: 16px">Match Details</p>
      </div>

      <div class="flex" style="font-size: 14px">
        <p style="width: 15%; font-weight: bold">Tournament:</p>
        <p>{{ match.tournament.title }}</p>
      </div>

      <div class="flex" style="font-size: 14px">
        <p style="width: 15%; font-weight: bold">Venue:</p>
        <p>{{ match.stadium }}</p>
      </div>

      <div class="flex" style="font-size: 14px">
        <p style="width: 15%; font-weight: bold">Date:</p>
        <p>{{ match.matchDate }}</p>
      </div>

      <div class="flex pb-4" style="font-size: 14px">
        <p style="width: 15%; font-weight: bold">Match Result:</p>
        <p>{{ match.teamOne.name + ' ' + (match.matchType === 'SEVENS' ? '7s' : '15s') + ' ' + match.teamOneScore + ' - ' + match.teamTwoScore + ' ' + (match.matchType === 'SEVENS' ? '7s' : '15s') + ' ' + match.teamOne.name }}</p>
      </div>

      <div class="flex">
        <div class="w-1/3 pr-4">
          <mat-form-field appearance="outline" class="w-full" floatLabel="always">
            <mat-label>Report Type</mat-label>
            <mat-select formControlName="reportType" placeholder="Select a Report">
              <mat-option *ngFor="let reportType of reportTypes" [value]="reportType.value">
                {{ reportType.viewValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="w-2/3 pt-3">
          <mat-slide-toggle formControlName="isNonPlayer">Non Player?</mat-slide-toggle>
        </div>
      </div>

      <div class="flex">
        <div class="w-1/3 pr-4">
          <mat-form-field appearance="outline" class="w-full" floatLabel="always">
            <mat-label>Team</mat-label>
            <mat-select formControlName="team" (selectionChange)="changePlayerList()" placeholder="Select a Team">
              <mat-option *ngFor="let team of teams" [value]="team">
                {{ team.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="w-1/3 pr-4" *ngIf="form.get('isNonPlayer')?.value">
          <mat-form-field appearance="outline" class="w-full" floatLabel="always">
            <mat-label>Full Name</mat-label>
            <input matInput formControlName="nonPlayerFullName" placeholder="Non Player Full Name">
          </mat-form-field>
        </div>
        <div class="w-1/3 pr-4" *ngIf="form.get('team')?.value && !form.get('isNonPlayer')?.value">
          <mat-form-field appearance="outline" class="w-full" floatLabel="always">
            <mat-label>Player</mat-label>
            <mat-select formControlName="player" placeholder="Select a Player">
              <mat-option *ngFor="let player of players" [value]="player">
                {{ player.jerseyNumber + ' - ' + player.fullName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="flex" style="font-weight: bold">
        Match Time
      </div>

      <div class="flex pb-4" style="font-size: 12px">
        Match Time of Event, in Minutes, Since the Start of the Match
      </div>

      <div class="flex">

        <div class="w-1/3 pr-4">
          <mat-form-field appearance="outline" class="w-full" floatLabel="always">
            <mat-label>Period of Match</mat-label>
            <mat-select formControlName="periodOfMatch" placeholder="Select a Period">
              <mat-option *ngFor="let matchPeriodType of matchPeriodTypes" [value]="matchPeriodType.value">
                {{ matchPeriodType.viewValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="w-1/3 pr-4">
          <mat-form-field appearance="outline" class="w-full" floatLabel="always">
            <mat-label>Match Time Minutes</mat-label>
            <input matInput formControlName="elapsedTimeOfPeriodMinutes"
                   (keypress)="allowOnlyNumbers($event)"
                   placeholder="Input">
            <div *ngIf="match.matchType === 'FIFTEEN_A_SIDE'">
              <div
                style="color: red; font-size: 8pt"
                *ngIf="form.controls['elapsedTimeOfPeriodMinutes']?.value != '' && form.controls['periodOfMatch']?.value === 2 && (form.controls['elapsedTimeOfPeriodMinutes']?.value < 40)">
                Time of Event Incorrect (must be more than 40 minutes)
              </div>
              <div
                style="color: red; font-size: 8pt"
                *ngIf="form.controls['elapsedTimeOfPeriodMinutes']?.value != '' && form.controls['periodOfMatch']?.value === 3 && form.controls['elapsedTimeOfPeriodMinutes']?.value < 80">
                Time of Event Incorrect (must be more than 80 minutes)
              </div>
            </div>
            <div *ngIf="match.matchType === 'SEVEN_A_SIDE'">
              <div
                style="color: red; font-size: 8pt"
                *ngIf="form.controls['elapsedTimeOfPeriodMinutes']?.value != '' && form.controls['periodOfMatch']?.value === 2 && (form.controls['elapsedTimeOfPeriodMinutes']?.value < 7)">
                Time of Event Incorrect (must be more than 7 minutes)
              </div>
              <div
                style="color: red; font-size: 8pt"
                *ngIf="form.controls['elapsedTimeOfPeriodMinutes']?.value != '' && form.controls['periodOfMatch']?.value === 3 && form.controls['elapsedTimeOfPeriodMinutes']?.value < 14">
                Time of Event Incorrect (must be more than 14 minutes)
              </div>
            </div>
          </mat-form-field>
        </div>

        <div class="w-1/3 pr-4">
          <mat-form-field appearance="outline" class="w-full" floatLabel="always">
            <mat-label>Match Time Seconds</mat-label>
            <input matInput formControlName="elapsedTimeOfPeriodSeconds"
                   (keypress)="allowOnlyNumbers($event)"
                   placeholder="Input">
          </mat-form-field>
        </div>
      </div>

      <div class="flex pb-4" style="font-weight: bold">
        Scoring
      </div>

      <div class="flex">

        <div class="w-1/3 pr-4">
          <mat-form-field appearance="outline" class="w-full" floatLabel="always">
            <mat-label>Score at Time of Event</mat-label>
            <input matInput formControlName="teamOneScoreAtTimeOfPeriod"
                   (keypress)="allowOnlyNumbers($event)"
                   placeholder="{{ teams[0].name }} Score at Time of Event">
          </mat-form-field>
        </div>

        <div class="w-1/3 pr-4">
          <mat-form-field appearance="outline" class="w-full" floatLabel="always">
            <mat-label>Score at Time of Event</mat-label>
            <input matInput formControlName="teamTwoScoreAtTimeOfPeriod"
                   (keypress)="allowOnlyNumbers($event)"
                   placeholder="{{ teams[1].name }} Score at Time of Event">
          </mat-form-field>
        </div>
      </div>

      <div class="flex pb-4" style="font-weight: bold">
        Report Details
      </div>

      <div class="flex">
        <div class="w-2/3 pr-4">
          <mat-form-field appearance="outline" class="w-full" floatLabel="always">
            <mat-label>Applicable Law</mat-label>
            <mat-select formControlName="applicableLaw" placeholder="Select a Law">
              <mat-option *ngFor="let law of laws" [value]="law" [matTooltip]="law.wording">
                {{ law.lawSection }} - {{ law.description }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="w-1/3 pr-4">
          <mat-form-field appearance="outline" class="w-full" floatLabel="always">
            <mat-label>Approximate Distance (Meters)</mat-label>
            <input matInput formControlName="proximityMeters"
                   (keypress)="allowOnlyNumbers($event)"
                   placeholder="Distance from the Referee to the Event"/>
          </mat-form-field>
        </div>
      </div>

      <div class="flex w-2/3 pr-4 pb-4">
        <mat-form-field appearance="outline" class="w-full h-full" style="height: 250px" floatLabel="always">
          <mat-label>Report Details</mat-label>
          <textarea
            matInput
            rows="9"
            cols="100"
            formControlName="reportDetails"
            placeholder="Detailed Description of Event">
        </textarea>
        </mat-form-field>
      </div>

      <div class="flex pb-4" style="font-weight: bold">
        Citing Commissioner's Details
      </div>

      <div class="flex">
        <div class="flex w-1/3 pr-4">
          <mat-form-field appearance="outline" class="w-full" floatLabel="always">
            <mat-label>Citing Commissioner Name</mat-label>
            <input matInput formControlName="citingCommissionerName">
          </mat-form-field>
        </div>
        <div class="flex w-1/3 pr-4">
          <mat-form-field appearance="outline" class="w-full" floatLabel="always">
            <mat-label>Union</mat-label>
            <input matInput formControlName="citingCommissionerUnion">
          </mat-form-field>
        </div>
        <div class="flex w-1/3 pr-4">
          <mat-form-field appearance="outline" class="w-full" floatLabel="always">
            <mat-label>Citing Commissioner Email Address</mat-label>
            <input matInput type="email" formControlName="citingCommissionerEmailAddress">
            <mat-error
              *ngIf="form.controls['citingCommissionerEmailAddress']?.invalid && form.controls['citingCommissionerEmailAddress']?.touched && form.controls['citingCommissionerEmailAddress']?.errors?.['email']">
              Invalid email format.
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
</div>
