import { Component, OnInit } from '@angular/core';
import { ControlContainer, FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MatchDTO } from 'src/app/dtos/MatchDTO';

@Component({
  selector: 'app-match-settings',
  templateUrl: './match-settings.component.html',
  styleUrls: ['./match-settings.component.css'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective }
  ]
})
export class MatchSettingsComponent implements OnInit {
  form?: FormGroup;
  matchSettingSubForm!: FormGroup;
  match?: MatchDTO;

  constructor(private fb: FormBuilder,
    private route: ActivatedRoute,
    private ctrlContainer: FormGroupDirective) {
      
  }

  ngOnInit(): void {
    this.form = this.ctrlContainer.form;
    this.matchSettingSubForm = this.fb.group({
      timePerHalf: ['', Validators.required],
      halfTimeLength: ['', Validators.required],
      extraTimeAllowed: [''],
      extraTimePerHalf: [''],
      yellowCardTime: ['', Validators.required],
      bloodBinTime: ['', Validators.required],
      hiaTime: ['', Validators.required],
      redCardLength: ['', Validators.required],
    });

    this.matchSettingSubForm.patchValue({
      timePerHalf: 40,
      halfTimeLength: 10,
      yellowCardTime: 10,
      bloodBinTime: 15,
      hiaTime: 12,
      extraTimePerHalf: 10,
      extraTimeAllowed: false,
      redCardLength: 0
    });

    this.form.addControl("matchSettings", this.matchSettingSubForm);

    const routeParams = this.route.snapshot.paramMap;
    console.log("MATCH COMPONENT " + routeParams.get("matchId"));
    if (routeParams.get("id") != null) {
      this.mapDataToForm()
    }
  }

  mapDataToForm() {
    console.log("restoring form values...");
    this.match = JSON.parse(localStorage.getItem("matchView")!);
    this.matchSettingSubForm?.patchValue({
      timePerHalf: this.match?.matchConfiguration.halfLength,
      halfTimeLength: this.match?.matchConfiguration.halfTimeLength,
      extraTimeAllowed: this.match?.matchConfiguration.extraTimeAllowed,
      extraTimePerHalf: this.match?.matchConfiguration.extraTimeHalfLength,
      yellowCardTime: this.match?.matchConfiguration.yellowCardLength,
      bloodBinTime: this.match?.matchConfiguration.bloodBinLength,
      hiaTime: this.match?.matchConfiguration.hiaLength,
      redCardLength: this.match?.matchConfiguration.redCardLength
    })
  }
}
