import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Flag } from 'src/app/interfaces/Flag';
import { TeamMapping } from 'src/app/interfaces/TeamMapping';
import { MatchService } from 'src/app/services/MatchService';
import { TournamentService } from 'src/app/services/tournament.service';

@Component({
  selector: 'app-team-mapping',
  templateUrl: './team-mapping.component.html',
  styleUrls: ['./team-mapping.component.css']
})
export class TeamMappingComponent implements OnInit {
  teamMappingForm!: FormGroup;
  selectedTeamFlag: String | undefined;
  flags?: Flag[];
  teamName!: string;
  teamAbbreviation!: string;
  teamBadge!: string;
  jerseyColor!: string;
  teamMapping?: TeamMapping;
  mappingId?: number;

  constructor(private fb: FormBuilder,
    private route: ActivatedRoute,
    private tournamentService: TournamentService,
    private _sanitizer: DomSanitizer,
    private matchService: MatchService,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<TeamMappingComponent>) {

  }

  ngOnInit(): void {
    if (localStorage.getItem("teamMapping") != null) {
      this.teamMapping = JSON.parse(localStorage.getItem("teamMapping")!);
      this.teamName = this.teamMapping!.name;
      this.teamAbbreviation = this.teamMapping!.abbreviation;
      this.jerseyColor = this.teamMapping!.jerseyColor;
      this.teamBadge = this.teamMapping!.teamBadge;
      this.mappingId = this.teamMapping!.id
      this.selectedTeamFlag = this.teamMapping!.teamBadge;
    }

    this.matchService.getFlags().subscribe((data: Flag[]) => {
      this.flags = data
    }, (error => {
      console.error("Error calling service: " + JSON.stringify(error));
      this.handleError(error, "Unable to get flags");
    }));
  }

  save(): void {
    if (this.mappingId != null) {
      this.update();
    } else {
      this.create();
    }
  }

  update(): void {
    let mapping: TeamMapping = {
      name: this.teamName,
      abbreviation: this.teamAbbreviation,
      jerseyColor: this.jerseyColor,
      teamBadge: this.teamBadge,
      id: this.mappingId
    }
    this.tournamentService.updateTeamMapping(this.mappingId!, mapping).subscribe(() => {
      this.dialogRef.close();
    }, (error => {
      console.error("Error calling service: " + JSON.stringify(error));
      this.handleError(error, "Unable to update mapping");
    }));
  }

  create(): void {
    let mapping: TeamMapping = {
      name: this.teamName,
      abbreviation: this.teamAbbreviation,
      jerseyColor: this.jerseyColor,
      teamBadge: this.teamBadge
    }
    this.tournamentService.createTeamMapping(mapping).subscribe(() => {
      this.dialogRef.close();
    }, (error => {
      console.error("Error calling service: " + JSON.stringify(error));
      this.handleError(error, "Unable to save mapping");
    }));
  }

  cancel(): void {
    this.dialogRef.close();
  }

  getSanitizedImage(content: String | undefined) {
    return this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,'
      + content);
  }

  getSanitizedImageWithName(imageName: String | undefined) {
    return "assets/images/flags/" + imageName
  }

  handleError(error: any, errorMessage: string): void {
    this._snackBar.open(errorMessage, 'OK', {
      horizontalPosition: "center",
      verticalPosition: "top",
    });
  }

}
