import {Component, inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute} from '@angular/router';
import {Tournament} from 'src/app/interfaces/Tournament';
import {TournamentService} from 'src/app/services/tournament.service';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatChipEditedEvent, MatChipInputEvent} from "@angular/material/chips";
import {LiveAnnouncer} from "@angular/cdk/a11y";

@Component({
  selector: 'app-tournament-emails',
  templateUrl: './tournament-emails.component.html',
  styleUrls: ['./tournament-emails.component.css']
})
export class TournamentEmailsComponent implements OnInit {
  rimsTournamentForm!: FormGroup;
  id!: number;
  eqId!: string;
  emails: string[] = [];
  title!: string;
  titleAbbreviation!: string;
  startDate!: Date;
  endDate!: Date;
  tournamentMapping!: Tournament;
  tournamentId!: number;

  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  constructor(private fb: FormBuilder,
              private route: ActivatedRoute,
              private tournamentService: TournamentService,
              private _snackBar: MatSnackBar,
              public dialogRef: MatDialogRef<TournamentEmailsComponent>) {

  }

  ngOnInit(): void {
    if (localStorage.getItem("tournamentEmailMapping") != null) {
      this.tournamentMapping = JSON.parse(localStorage.getItem("tournamentEmailMapping")!)
      this.id = this.tournamentMapping!.id;
      this.eqId = this.tournamentMapping!.tournamentEqId;

      if (this.tournamentMapping!.tournamentReportEmails) {
        this.emails.push(...(this.tournamentMapping!.tournamentReportEmails).split(","));
      }

      this.title = this.tournamentMapping!.title;
      this.titleAbbreviation = this.tournamentMapping!.titleAbbreviation;
      this.startDate = this.tournamentMapping!.startDate;
      this.endDate = this.tournamentMapping!.endDate;
    }
  }

  save(): void {
    this.updateEmail();
  }

  updateEmail(): void {
    let tournament: Tournament = {
      id: this.id,
      tournamentEqId: this.eqId,
      tournamentReportEmails: this.emails.join(","),
      title: this.title,
      titleAbbreviation: this.titleAbbreviation,
      startDate: this.startDate,
      endDate: this.endDate
    }
    this.tournamentService.updateTournamentEmailAddress(tournament).subscribe(() => {
      this.dialogRef.close();
    }, (error => {
      console.error("Error calling service: " + JSON.stringify(error));
      this.handleError(error, "Unable to update tournament email");
    }));
  }

  cancel(): void {
    this.dialogRef.close();
  }

  handleError(error: any, errorMessage: string): void {
    this._snackBar.open(errorMessage, 'OK', {
      horizontalPosition: "center",
      verticalPosition: "top",
    });
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim().toLowerCase();

    if (!value) {
      event.chipInput!.clear();
      return;
    }

    const emailRegex = new RegExp("[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?");

    if (!emailRegex.test(value)) {
      this._snackBar.open('Invalid email address', 'OK', {
        horizontalPosition: "center",
        verticalPosition: "top",
        duration: 3000
      });

      return;
    }

    this.emails.push(value);

    // Clear the input value
    event.chipInput!.clear();
  }

  remove(email: string): void {
    const index = this.emails.indexOf(email);

    if (index >= 0) {
      this.emails.splice(index, 1);
    }
  }

  edit(email: string, event: MatChipEditedEvent) {
    const value = event.value.trim().toLowerCase();

    // Remove email if it no longer has a name
    if (!value) {
      this.remove(email);
      return;
    }

    const emailRegex = new RegExp("[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?");

    if (!emailRegex.test(value)) {
      this._snackBar.open('Invalid email address', 'OK', {
        horizontalPosition: "center",
        verticalPosition: "top",
        duration: 3000
      });

      return;
    }

    // Edit existing email
    const index = this.emails.indexOf(email);

    if (index >= 0) {
      this.emails[index] = value;
    }
  }
}
