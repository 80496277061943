import {Component, OnInit} from '@angular/core';
import {ControlContainer, FormBuilder, FormGroup, FormGroupDirective} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {MatchDTO} from 'src/app/dtos/MatchDTO';
import {PortalUserService} from "../../services/PortalUserService";

@Component({
  selector: 'app-officials-details',
  templateUrl: './officials-details.component.html',
  styleUrls: ['./officials-details.component.css'],
  viewProviders: [
    {provide: ControlContainer, useExisting: FormGroupDirective}
  ]
})
export class OfficialsDetailsComponent implements OnInit {
  form?: FormGroup;
  officialsSubForm!: FormGroup;
  match?: MatchDTO;

  constructor(private fb: FormBuilder,
    private route: ActivatedRoute,
    private portalUserService: PortalUserService,
    private ctrlContainer: FormGroupDirective) {}

  ngOnInit(): void {
    this.form = this.ctrlContainer.form;
    this.officialsSubForm = this.fb.group({
      referee: [''],
      assistantReferee1: [''],
      assistantReferee2: [''],
      matchOfficial4: [''],
      matchOfficial5: [''],
      matchOfficial6: [''],
      tmo: ['']
    });

    if (this.portalUserService.getUserRole() === 'CITING') {
      this.form.disable();
      this.officialsSubForm.disable();
    }

    this.form.addControl("officials", this.officialsSubForm);

    const routeParams = this.route.snapshot.paramMap;
    console.log("MATCH COMPONENT " + routeParams.get("matchId"));
    if (routeParams.get("id") != null) {
      this.mapDataToForm()
    }

  }

  mapDataToForm() {
    console.log("restoring form values...");
    this.match = JSON.parse(localStorage.getItem("matchView")!);
    this.officialsSubForm?.patchValue({
      referee: this.match?.referee.fullName,
      assistantReferee1: this.match?.assistantRefereeOne.fullName,
      assistantReferee2: this.match?.assistantRefereeTwo.fullName,
      matchOfficial4: this.match?.matchOfficialOne.fullName,
      matchOfficial5: this.match?.matchOfficialTwo.fullName,
      matchOfficial6: this.match?.matchOfficialThree.fullName,
      tmo: this.match?.tmo?.fullName
    })
  }
}
