import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {TournamentsResponseDTO} from "../dtos/TournamentsResponseDTO";
import {GetTeamMappingsResponseDTO} from "../dtos/GetTeamMappingsResponseDTO";
import {TeamMapping} from "../interfaces/TeamMapping";
import {Tournament} from "../interfaces/Tournament";
import {environment} from "src/environments/environment";
import {TournamentDTO} from "../dtos/TournamentDTO";
import {GetTournamentTeamMappingsResponseDTO} from "../dtos/GetTournamentTeamMappingsResponseDTO";
import {UpdateTournamentTeamMappingRequestDTO} from "../dtos/UpdateTournamentTeamMappingRequestDTO";
import {GetAppTournamentLoginsResponseDTO} from "../dtos/GetAppTournamentLoginsResponseDTO";

@Injectable({
  providedIn: 'root'
})
export class TournamentService {
  url = environment.apiUrl;

  constructor(
    private http: HttpClient
  ) {
  }

  getTournaments() {
    return this.http.get<TournamentsResponseDTO>(this.url + "tournaments");
  }

  syncTournament(id: number) {
    return this.http.post(this.url + "tournaments/" + id + "/matches", null);
  }

  syncMatch(eqId: string) {
    return this.http.post(this.url + "tournaments/match/" + eqId, null);
  }

  getTeamMappings() {
    return this.http.get<GetTeamMappingsResponseDTO>(this.url + "tournaments/team/mappings");
  }

  getTournamentTeamMappings(tournamentId: number) {
    return this.http.get<GetTournamentTeamMappingsResponseDTO>(this.url + "tournament-team-mappings/" + tournamentId);
  }

  createTeamMapping(teamMapping: TeamMapping) {
    return this.http.post(this.url + "tournaments/team/mappings", teamMapping);
  }

  updateTeamMapping(id: number, teamMapping: TeamMapping) {
    return this.http.put(this.url + "tournaments/team/mappings/" + id, teamMapping);
  }

  updateTournamentTeamMapping(id: number, tournamentTeamMapping: UpdateTournamentTeamMappingRequestDTO) {
    return this.http.put(this.url + "tournament-team-mappings/" + id, tournamentTeamMapping);
  }

  syncRimsTournaments() {
    return this.http.post(this.url + "tournaments/sync-rims-tournaments", null);
  }

  createTournament(tournament: TournamentDTO) {
    let createTournamentRequest = {
      tournament: tournament
    }

    return this.http.post(this.url + "tournaments", createTournamentRequest);
  }

  updateTournament(id: number, tournament: TournamentDTO) {
    let updateTournamentRequestDTO = {
      tournament: tournament
    }

    return this.http.put(this.url + "tournaments/" + id, updateTournamentRequestDTO);
  }

  deleteTeamMapping(id: number) {
    return this.http.delete(this.url + "tournaments/team/mappings/" + id);
  }

  updateTournamentEmailAddress(tournament: Tournament) {
    return this.http.put(this.url + "tournaments/" + tournament.id + "/emails", tournament);
  }

  deleteTournament(id: number) {
    return this.http.delete(this.url + "tournaments/" + id);
  }

  getAppTournamentLoginPins(matchId: number) {
    return this.http.get<GetAppTournamentLoginsResponseDTO>(this.url + "app-tournament-logins/by-match/" + matchId);
  }
}
