<div class="flex flex-row w-full items-center">
  <div class="basis-1/4">
    <mat-form-field appearance="outline" class="w-full">
      <mat-label>Search</mat-label>
      <input type="search" matInput placeholder="Search" [(ngModel)]="filterCriteria" (keyup)="doFilter()">
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
  </div>

  <div class="basis-3/4">
    <button mat-raised-button color="warn" class="btn-4 ml-3 float-left mb-6"
            (click)="resetFilters()" matTooltip="Reset Filters">
      <mat-icon class="btn-mat-icon-white">clear</mat-icon>
    </button>
    <button mat-raised-button color="accent" class="btn-4 ml-3 float-left mb-6"
            (click)="loadPortalUsers()" matTooltip="Reload Portal Users">
      <mat-icon class="btn-mat-icon-white">refresh</mat-icon>
    </button>
    <button mat-raised-button (click)="createOrUpdatePortalUser()" color="accent" class="float-right btn "
            matTooltip="New Portal User">New Portal User
    </button>
  </div>
</div>
<div class="pt-5 h-full">
  <table mat-table [dataSource]="datasource" matSort #table>
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>EMAIL</th>
      <td mat-cell *matCellDef="let element"> {{ element.email }}</td>
    </ng-container>
    <ng-container matColumnDef="roleType">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>ROLE</th>
      <td mat-cell *matCellDef="let element"> {{ element.portalUserRoleType }}</td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef> ACTIONS</th>
      <td mat-cell *matCellDef="let element">
        <button mat-raised-button color="primary" class="btn-3 mr-3" (click)="createOrUpdatePortalUser(element.id)"
                matTooltip="Edit Portal User">
          <mat-icon class="btn-mat-icon-darkblue">edit</mat-icon>
        </button>
        <button mat-raised-button color="primary" class="btn-3 mr-3"
                [routerLink]="['/user-assigned-tournaments', element.id]"
                matTooltip="Go to User Assigned Tournaments">
          <mat-icon class="btn-mat-icon-darkblue">open_in_new</mat-icon>
        </button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator #paginator [pageSizeOptions]="[15,25,50]" [pageSize]="15" showFirstLastButtons></mat-paginator>
</div>
