import {HttpClient, HttpParams} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {MatchDTO} from "../dtos/MatchDTO";
import {Observable} from "rxjs";
import {Flag} from "../interfaces/Flag";
import {GetMatchPinsResponseDto} from "../dtos/GetMatchPinsResponseDto";
import {HistoryDTO} from "../dtos/HistoryDTO";
import {CreateMatchDTO} from "../dtos/CreateMatchDTO";
import {Match} from "../interfaces/Match";
import {Officials} from "../interfaces/Officials";
import {PlayerDTO} from "../dtos/PlayerDTO";
import {TeamDTO} from "../dtos/TeamDTO";
import {CreateTeamPlayersRequestDTO} from "../dtos/CreateTeamPlayersRequestDTO";
import {MatchSettings} from "../interfaces/MatchSettings";
import {MatchConfigurationDTO} from "../dtos/MatchConfigurationDTO";
import {UpdateOfficialsRequestDTO} from "../dtos/UpdateOfficialsRequestDTO";
import {UpdateTeamPlayersRequestDTO} from "../dtos/UpdateTeamPlayersRequestDTO";
import {environment} from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class MatchService {

  url = environment.apiUrl;

  constructor(
    private http: HttpClient
  ) {
  }

  getMatches(tournamentId: Number | null, pageNumber: number, pageSize: number) {

    if (tournamentId != null) {
      return this.http.get<any>(this.url + "matches?tournamentId=" + tournamentId + "&pageNumber=" + pageNumber + "&pageSize=" + pageSize);
    } else {
      return this.http.get<any>(this.url + "matches?pageNumber=" + pageNumber + "&pageSize=" + pageSize);
    }
  }

  resetMatch(id: number) {
    return this.http.put(this.url + "matches/" + id + "/reset", null)
  }

  showHideMatch(id: number, visible: boolean) {
    if (!visible) {
      return this.http.put(this.url + "matches/" + id + "/show-in-app", null)
    } else {
      return this.http.put(this.url + "matches/" + id + "/hide-from-app", null)
    }
  }

  deleteMatch(id: number) {
    return this.http.delete(this.url + "matches/" + id)
  }

  getFlags(): Observable<Flag[]> {
    return this.http.get<Flag[]>(this.url + "flags");
  }

  getMatch(id: number) {
    return this.http.get<MatchDTO>(this.url + "matches/" + id);
  }

  getMatchPins(id: number) {
    return this.http.get<GetMatchPinsResponseDto>(this.url + "matches/" + id + "/pins")
  }

  getMatchEvents(id: number) {
    return this.http.get<HistoryDTO>(this.url + "match/history?matchId=" + id);
  }

  createMatch(m: Match, o: Officials) {

    let match: CreateMatchDTO = {
      matchDate: m.matchDate,
      tournament: {
        title: m.tournament.title,
        id: m.tournament.id
      },
      stadium: m.venue,
      country: m.country,
      crowdSize: m.crowdSize,
      conditions: m.conditions,
      teamOne: {
        name: m.homeTeam,
        jerseyColor: m.homeTeamColor!,
        teamBadge: m.homeTeamBadge
      },
      teamTwo: {
        name: m.visitingTeam,
        jerseyColor: m.visitingTeamColor!,
        teamBadge: m.visitingTeamBadge
      },
      matchOfficials: [{
        fullName: o.referee,
        role: "REFEREE"
      },
        {
          fullName: o.assistantReferee1,
          role: "ASSISTANT_REFEREE_ONE"
        },
        {
          fullName: o.assistantReferee2,
          role: "ASSISTANT_REFEREE_TWO"
        },
        {
          fullName: o.matchOfficial4,
          role: "MATCH_OFFICIAL_ONE"
        },
        {
          fullName: o.matchOfficial5,
          role: "MATCH_OFFICIAL_TWO"
        },
        {
          fullName: o.matchOfficial6,
          role: "MATCH_OFFICIAL_THREE"
        },
        {
          fullName: o.tmo,
          role: "TMO"
        }],
      matchType: m.matchType,
      numberOfPlayers: m.teamSize,
      substitutionRule: m.substitutionRule
    }

    console.log("Creating match");
    return this.http.post<MatchDTO>(this.url + "matches", match);
  }

  addTeamPlayers(teamId: number, players: PlayerDTO[]) {
    let request: CreateTeamPlayersRequestDTO = {
      players: players
    };
    console.log("Creating team " + teamId);
    return this.http.post<TeamDTO>(this.url + "teams/" + teamId + "/players", request);
  }

  addMatchSettings(matchId: number, s: MatchSettings) {
    let matchConfig: MatchConfigurationDTO = {
      bloodBinLength: s.bloodBinTime,
      extraTimeAllowed: s.extraTimeAllowed,
      extraTimeHalfLength: s.extraTimePerHalf,
      halfLength: s.timePerHalf,
      halfTimeLength: s.halfTimeLength,
      hiaLength: s.hiaTime,
      yellowCardLength: s.yellowCardTime,
      numberOfSubstitutions: s.numberOfSubstitutions,
      redCardLength: s.redCardLength
    }

    console.log("Creating match config");
    return this.http.post<MatchConfigurationDTO>(this.url + "matches/" + matchId + "/configuration", matchConfig);
  }

  updateMatch(m: Match, matchId: number, homeTeamId: number, visitingTeamId: number) {
    let match: CreateMatchDTO = {
      id: matchId,
      matchDate: m.matchDate,
      tournament: {
        id: m.tournament.id,
        title: m.tournament.title
      },
      stadium: m.venue,
      country: m.country,
      crowdSize: m.crowdSize,
      conditions: m.conditions,
      teamOne: {
        id: homeTeamId,
        name: m.homeTeam,
        jerseyColor: m.homeTeamColor!,
        teamBadge: m.homeTeamBadge
      },
      teamTwo: {
        id: visitingTeamId,
        name: m.visitingTeam,
        jerseyColor: m.visitingTeamColor!,
        teamBadge: m.visitingTeamBadge
      },
      matchType: m.matchType,
      numberOfPlayers: m.teamSize,
      substitutionRule: m.substitutionRule
    }

    console.log("Creating match");
    return this.http.put<MatchDTO>(this.url + "matches/" + matchId, match);
  }

  updateMatchOfficials(o: UpdateOfficialsRequestDTO, matchId: number) {
    return this.http.put<UpdateOfficialsRequestDTO>(this.url + "matches/" + matchId + "/officials", o);
  }

  updateTeamPlayers(players: UpdateTeamPlayersRequestDTO, teamId: number) {
    return this.http.put<UpdateTeamPlayersRequestDTO>(this.url + "teams/" + teamId + "/players", players);
  }

  updateMatchSettings(matchSettings: MatchConfigurationDTO, matchId: number) {
    return this.http.put<MatchConfigurationDTO>(this.url + "matches/" + matchId + "/configuration", matchSettings);
  }

  logoutUserByPin(pin: number) {
    return this.http.put(this.url + "login/logout-pin-user/" + pin, null);
  }
}
