import {Component, Input} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {PlayerDTO} from 'src/app/dtos/PlayerDTO';
import {PortalUserService} from "../../services/PortalUserService";

@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.css']
})
export class PlayerComponent {
  @Input() player!: PlayerDTO;
  @Input() playerForm!: FormGroup;
  @Input() index!: number;

  // playerName: String | undefined;
  // position: number | undefined;
  // looseHead: boolean | undefined = false;
  // tightHead: boolean | undefined = false;
  // hooker: boolean | undefined = false;
  // u19: boolean | undefined = false;

  constructor(private portalUserService: PortalUserService) {
  }

  ngOnInit(): void {

    if (this.portalUserService.getUserRole() === 'CITING') {
      this.playerForm.disable();
    }
    // this.playerName = this.player?.fullName;
    // this.position = this.player?.position;
    // this.hooker = this.player?.hooker;
    // this.looseHead = this.player?.hooker;
    // this.tightHead = this.player?.tightHead;
    // this.u19 = this.player?.u19;
  }

  allowOnlyNumbers(event: KeyboardEvent): void {
    const charCode = event.which || event.keyCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  }
}
