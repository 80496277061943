import {Component, ViewChild} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {Router} from '@angular/router';
import {TournamentsResponseDTO} from 'src/app/dtos/TournamentsResponseDTO';
import {DateRange} from 'src/app/interfaces/DateRange';
import {Tournament} from 'src/app/interfaces/Tournament';
import {MatchService} from 'src/app/services/MatchService';
import {TournamentService} from 'src/app/services/tournament.service';
import {TournamentEmailsComponent} from '../tournament-emails/tournament-emails.component';
import {TournamentDetailsComponent} from "../tournament-details/tournament-details.component";
import {ReportingService} from "../../services/ReportingService";
import {PortalUserService} from "../../services/PortalUserService";
import {
  UserAssignedTournamentDetailsComponent
} from "../user-assigned-tournament-details/user-assigned-tournament-details.component";

@Component({
  selector: 'app-tournaments',
  templateUrl: './tournaments.component.html',
  styleUrls: ['./tournaments.component.css']
})
export class TournamentsComponent {
  filterCriteria?: string;
  datasource: any;
  tournaments: Tournament[] = [];
  displayedColumns: string[] = ['rimsEqId', 'title', 'startDate', 'endDate', 'actions'];
  @ViewChild('paginator') paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });

  constructor(
    private matchService: MatchService,
    protected portalUserService: PortalUserService,
    private router: Router,
    private _snackBar: MatSnackBar,
    private tournamentService: TournamentService,
    private reportingService: ReportingService,
    public dialog: MatDialog
  ) {
  }

  ngAfterViewInit(): void {

  }

  ngOnInit(): void {
    localStorage.removeItem("matchView");
    this.loadTournaments();
  }

  doFilter() {
    this.datasource.filter = this.filterCriteria!.trim().toLocaleLowerCase();
  }

  doDateFilter() {
    let dr: DateRange = this.range.value;
    console.log(dr);
    let fd = this.tournaments.filter(value => new Date(value.startDate) >= new Date(dr.start!) && new Date(value.startDate) <= new Date(dr.end!));
    console.log(fd);
    this.datasource = new MatTableDataSource(fd);
  }

  resetFilters() {
    this.range.reset();
    this.filterCriteria = "";
    this.loadTournaments();
  }

  loadTournaments(): void {
    this.tournamentService.getTournaments().subscribe((data: TournamentsResponseDTO) => {
      this.tournaments = data.tournaments;
      this.datasource = new MatTableDataSource(this.tournaments);
      this.datasource.sort = this.sort;
      this.datasource.paginator = this.paginator;
    }, (error => {
      console.error("Error calling service: " + JSON.stringify(error));
      this.handleError(error, "Unable to get tournaments");
    }));
  }

  syncRimsTournaments(): void {
    this.tournamentService.syncRimsTournaments().subscribe(() => {
      this.loadTournaments();
    }, (error => {
      console.error("Error calling service: " + JSON.stringify(error));
      this.handleError(error, "Unable to sync rims tournaments");
    }));
  }

  deleteTournament(id: number): void {
    this.tournamentService.deleteTournament(id).subscribe((data: any) => {
      this.handleError("", "Tournament deleted");
      this.loadTournaments();
    }, error => {
      this.handleError(error, "Unable to delete tournament");
    });
  }

  syncTournament(id: number) {
    console.log("syncing tournament " + id);
    this.tournamentService.syncTournament(id).subscribe(() => {
      console.log("tournament synced");
    }, error => {
      if (error.status === 412) {
        this.handleError(error, "Match is in progress, and cannot be deleted");
      } else {
        console.error("Error calling service: " + JSON.stringify(error));
        this.handleError(error, "Unable to delete match");
      }
    });
  }

  editTournamentEmailAddresses(id: number): void {
    const found = this.tournaments.find((obj) => {
      return obj.id == id;
    })
    localStorage.setItem('tournamentEmailMapping', JSON.stringify(found));
    console.log(localStorage.getItem('tournamentEmailMapping'))
    const dialogRef = this.dialog.open(TournamentEmailsComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.loadTournaments();
      localStorage.removeItem('tournamentEmailMapping');
    });
  }

  createNewTournament(): void {
    const dialogRef = this.dialog.open(TournamentDetailsComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.loadTournaments();
      localStorage.removeItem('tournament');
    });
  }

  editTournamentDetails(id: number): void {
    const found = this.tournaments.find((obj) => {
      return obj.id == id;
    })
    localStorage.setItem('tournament', JSON.stringify(found));
    const dialogRef = this.dialog.open(TournamentDetailsComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.loadTournaments();
      localStorage.removeItem('tournament');
    });
  }

  handleError(error: any, errorMessage: string): void {
    this._snackBar.open(errorMessage, 'OK', {
      horizontalPosition: "center",
      verticalPosition: "top",
    });
  }

  tournamentLoginCodes(id: number, tournamentName: string) {

    this.reportingService.getTournamentCodesReport(id).subscribe((response) => {
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(new Blob([response.body], {type: response.body.type}));

      const contentDisposition = response.headers.get('content-disposition');
      const fileName = tournamentName + "_Tournament_Login_Codes.pdf";
      downloadLink.download = fileName;
      downloadLink.click();
    })
  }

  tournamentLoginCodesExcel(id: number, tournamentName: string) {

    this.reportingService.getTournamentCodesExcel(id).subscribe((response) => {
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(new Blob([response.body], {type: response.body.type}));

      const contentDisposition = response.headers.get('content-disposition');
      const fileName = tournamentName + "_Tournament_Login_Codes.xlsx";
      downloadLink.download = fileName;
      downloadLink.click();
    })
  }

  createUserAssignedTournament(tournamentId: number): void {
    const dialogRef = this.dialog.open(UserAssignedTournamentDetailsComponent);

    localStorage.setItem('tournamentIdToAssign', String(tournamentId));

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');

      localStorage.removeItem('tournamentIdToAssign');
    });
  }
}
