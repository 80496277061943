import {Component, ViewChild} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {Router} from '@angular/router';
import {PortalUser} from 'src/app/interfaces/PortalUser';
import {PortalUserService} from "../../services/PortalUserService";
import {PortalUsersResponseDTO} from "../../dtos/PortalUsersResponseDTO";
import {PortalUserDetailsComponent} from "../portal-user-details/portal-user-details.component";

@Component({
  selector: 'app-portal-users',
  templateUrl: './portal-users.component.html',
  styleUrls: ['./portal-users.component.css']
})
export class PortalUsersComponent {
  filterCriteria?: string;
  datasource: any;
  portalUsers: PortalUser[] = [];
  displayedColumns: string[] = ['email', 'roleType', 'actions'];
  @ViewChild('paginator') paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });

  constructor(
    private portalUserService: PortalUserService,
    private router: Router,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog
  ) {
  }

  ngAfterViewInit(): void {

  }

  ngOnInit(): void {
    this.loadPortalUsers();
  }

  doFilter() {
    this.datasource.filter = this.filterCriteria!.trim().toLocaleLowerCase();
  }

  resetFilters() {
    this.range.reset();
    this.filterCriteria = "";
    this.loadPortalUsers();
  }

  loadPortalUsers(): void {
    this.portalUserService.getAllPortalUsers().subscribe((data: PortalUsersResponseDTO) => {
      this.portalUsers = data.portalUsers;
      this.datasource = new MatTableDataSource(this.portalUsers);
      this.datasource.sort = this.sort;
      this.datasource.paginator = this.paginator;
    }, (error => {
      console.error("Error calling service: " + JSON.stringify(error));
      this.handleError(error, "Unable to get portal users");
    }));
  }

  createOrUpdatePortalUser(id?: number): void {

    if (id != null) {
      const found = this.portalUsers.find((obj) => {
        return obj.id == id;
      })

      localStorage.setItem('portalUserDetails', JSON.stringify(found));
    }

    const dialogRef = this.dialog.open(PortalUserDetailsComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.loadPortalUsers();
      localStorage.removeItem('portalUserDetails');
    });
  }

  handleError(error: any, errorMessage: string): void {
    this._snackBar.open(errorMessage, 'OK', {
      horizontalPosition: "center",
      verticalPosition: "top",
    });
  }
}
