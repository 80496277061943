<mat-card>
  <mat-card-header>
    <div class="flex flex-row w-full">
      <div class="flex flex-col w-full">
        <div class="flex flex-row justify-center items-center gap-4">
          <div class="font-semibold text-lg">Assign Tournament to User</div>
        </div>
      </div>
    </div>
  </mat-card-header>
  <mat-card-content>
    <div class="grid grid-cols-2 gap-3 p-10" style="min-width: 500px">
      <mat-form-field appearance="outline">
        <mat-label>Portal User</mat-label>
        <mat-select [(ngModel)]="selectedPortalUser" [disabled]="portalUserId != null" (selectionChange)="portalUserSelected()" required>
          <mat-option *ngFor="let portalUser of portalUsers" [value]="portalUser">
            {{ portalUser.email }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Tournament</mat-label>
        <mat-select [(ngModel)]="selectedTournament" [disabled]="tournamentId != null" (selectionChange)="tournamentSelected()" required>
          <mat-option *ngFor="let tournament of tournaments" [value]="tournament">
            {{ tournament.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="grid grid-cols-2 gap-3">
      <button mat-raised-button (click)="save()" color="accent" class="float-right btn ">Save</button>
      <button mat-raised-button (click)="cancel()" color="warn" class="float-right btn ">Cancel</button>
    </div>
  </mat-card-content>
</mat-card>
