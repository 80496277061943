import {Component, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DomSanitizer} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';
import {MatchService} from 'src/app/services/MatchService';
import {TournamentService} from 'src/app/services/tournament.service';
import {MatChipEditedEvent, MatChipInputEvent} from "@angular/material/chips";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {UpdateTournamentTeamMappingRequestDTO} from "../../dtos/UpdateTournamentTeamMappingRequestDTO";

@Component({
  selector: 'app-team-mapping',
  templateUrl: './tournament-team-mapping.component.html',
  styleUrls: ['./tournament-team-mapping.component.css']
})
export class TournamentTeamMappingComponent implements OnInit {
  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  teamEmails: string[] = [];
  tournamentTeamMappingId?: number;

  constructor(private fb: FormBuilder,
              private route: ActivatedRoute,
              private tournamentService: TournamentService,
              private _sanitizer: DomSanitizer,
              private matchService: MatchService,
              private _snackBar: MatSnackBar,
              public dialogRef: MatDialogRef<TournamentTeamMappingComponent>) {

  }

  ngOnInit(): void {
    if (localStorage.getItem("tournamentTeamMapping") != null) {
      let tournamentTeamMapping = JSON.parse(localStorage.getItem("tournamentTeamMapping")!);

      if (tournamentTeamMapping.teamEmails) {
        this.teamEmails.push(...(tournamentTeamMapping!.teamEmails).split(","));
      }
      this.tournamentTeamMappingId = tournamentTeamMapping!.id
    }
  }

  save(): void {
    this.update();
  }

  update(): void {
    let mapping: UpdateTournamentTeamMappingRequestDTO = {
      teamEmails: this.teamEmails.join(",")
    }
    this.tournamentService.updateTournamentTeamMapping(this.tournamentTeamMappingId!, mapping).subscribe(() => {
      this.dialogRef.close();
    }, (error => {
      console.error("Error calling service: " + JSON.stringify(error));
      this.handleError(error, "Unable to update team tournament mapping");
    }));
  }

  cancel(): void {
    this.dialogRef.close();
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim().toLowerCase();

    if (!value) {
      event.chipInput!.clear();
      return;
    }

    const emailRegex = new RegExp("[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?");

    if (!emailRegex.test(value)) {
      this._snackBar.open('Invalid email address', 'OK', {
        horizontalPosition: "center",
        verticalPosition: "top",
        duration: 3000
      });

      return;
    }

    this.teamEmails.push(value);

    // Clear the input value
    event.chipInput!.clear();
  }

  remove(email: string): void {
    const index = this.teamEmails.indexOf(email);

    if (index >= 0) {
      this.teamEmails.splice(index, 1);
    }
  }

  edit(email: string, event: MatChipEditedEvent) {
    const value = event.value.trim().toLowerCase();

    // Remove email if it no longer has a name
    if (!value) {
      this.remove(email);
      return;
    }

    const emailRegex = new RegExp("[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?");

    if (!emailRegex.test(value)) {
      this._snackBar.open('Invalid email address', 'OK', {
        horizontalPosition: "center",
        verticalPosition: "top",
        duration: 3000
      });

      return;
    }

    // Edit existing email
    const index = this.teamEmails.indexOf(email);

    if (index >= 0) {
      this.teamEmails[index] = value;
    }
  }

  handleError(error: any, errorMessage: string): void {
    this._snackBar.open(errorMessage, 'OK', {
      horizontalPosition: "center",
      verticalPosition: "top",
    });
  }

}
