import {Component, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MatchPeriodType} from "../../interfaces/MatchPeriodType";
import {Law} from "../../interfaces/Law";
import {LawService} from "../../services/LawService";
import {MatchService} from "../../services/MatchService";
import {MatchDTO} from "../../dtos/MatchDTO";
import {TeamDTO} from "../../dtos/TeamDTO";
import {PlayerDTO} from "../../dtos/PlayerDTO";
import {SanctionsService} from "../../services/sanctions.service";
import {CreateOrUpdateCitingReportRequestDTO} from "../../dtos/CreateOrUpdateCitingReportRequestDTO";
import {SanctionDTO} from "../../dtos/SanctionDTO";
import {ReportType} from "../../interfaces/ReportType";
import {PortalUserService} from "../../services/PortalUserService";

@Component({
  selector: 'citing-report-details',
  templateUrl: './citing-report-details.component.html',
  styleUrls: ['./citing-report-details.component.css']
})
export class CitingReportDetailsComponent implements OnInit {

  form!: FormGroup;
  match!: MatchDTO;
  teams: TeamDTO[] = [];
  players?: PlayerDTO[];
  laws?: Law[];
  sanctionId?: number;
  sanctionReport?: SanctionDTO;
  matchId?: number;
  isNewCitingReport: boolean = true;

  matchPeriodTypes: MatchPeriodType[] = [
    {value: 1, viewValue: '1st Half'},
    {value: 2, viewValue: '2nd Half'},
    {value: 3, viewValue: 'Extra Time'}
  ];

  reportTypes: ReportType[] = [
    {value: 'WARNING', viewValue: 'WARNING'},
    {value: 'CITING', viewValue: 'CITING'}
  ];

  constructor(
    private router: Router,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private lawService: LawService,
    private matchService: MatchService,
    private sanctionService: SanctionsService,
    private portalUserService: PortalUserService,
    public dialogRef: MatDialogRef<CitingReportDetailsComponent>
  ) {
  }

  ngOnInit(): void {

    if (localStorage.getItem("addReportMatchId") != null) {
      this.matchId = Number(localStorage.getItem("addReportMatchId"));
    }

    if (localStorage.getItem("editReportId")) {
      this.sanctionId = Number(localStorage.getItem("editReportId"));

      this.sanctionService.getSanctionById(this.sanctionId!).subscribe(sanctionDTO => {
        this.sanctionReport = sanctionDTO;
        this.matchId = sanctionDTO.matchId;
        this.isNewCitingReport = false;
        this.matchService.getMatch(sanctionDTO.matchId).subscribe(value => {
          this.match = value;

          this.teams.push(this.match.teamOne);
          this.teams.push(this.match.teamTwo);

          this.form.patchValue({
            reportType: this.reportTypes?.find(reportType => sanctionDTO.reportType == reportType.value)?.value
          });

          if (sanctionDTO.teamId != null) {

            this.form.patchValue({
              team: this.match.teamOne.id == sanctionDTO.teamId ? this.match.teamOne : this.match.teamTwo
            });

            if (this.form.get('team')?.value == this.match.teamOne) {
              this.players = this.match.teamOne.players;
            } else {
              this.players = this.match.teamTwo.players;
            }

            if (sanctionDTO.sanctionedPlayerId != null) {
              this.form.patchValue({
                player: this.players?.find(player => player.id === sanctionDTO.sanctionedPlayerId)
              });
            }
          }
        });
        
        this.form.patchValue({
          periodOfMatch: sanctionDTO.whichHalf,
          isNonPlayer: sanctionDTO.sanctionedNameOnAdditional != null,
          nonPlayerFullName: sanctionDTO.sanctionedNameOnAdditional,
          elapsedTimeOfPeriodMinutes: sanctionDTO.timeOfEvent != null ? sanctionDTO.timeOfEvent.split(':')[0] : null,
          elapsedTimeOfPeriodSeconds: sanctionDTO.timeOfEvent != null ? sanctionDTO.timeOfEvent.split(':')[1] : null,
          teamOneScoreAtTimeOfPeriod: sanctionDTO.scoreAtTimeOfEvent != null ? sanctionDTO.scoreAtTimeOfEvent.split('-')[0] : null,
          teamTwoScoreAtTimeOfPeriod: sanctionDTO.scoreAtTimeOfEvent != null ? sanctionDTO.scoreAtTimeOfEvent.split('-')[1] : null,
          proximityMeters: sanctionDTO.proximityMetres,
          reportDetails: sanctionDTO.reportDetail,
          citingCommissionerName: sanctionDTO.citingCommissionerName,
          citingCommissionerEmailAddress: sanctionDTO.citingCommissionerEmailAddress,
          citingCommissionerUnion: sanctionDTO.citingCommissionerUnion
        });

        this.lawService.getLaws().subscribe((data: any) => {
          this.laws = data;

          this.form.patchValue({
            applicableLaw: this.laws?.find(law => law.lawSection === sanctionDTO.lawSection)
          });
        });
      });
    }

    this.form = new FormGroup({
      periodOfMatch: new FormControl(''),
      reportType: new FormControl(''),
      team: new FormControl(''),
      player: new FormControl(''),
      isNonPlayer: new FormControl(''),
      nonPlayerFullName: new FormControl(''),
      elapsedTimeOfPeriodMinutes: new FormControl(''),
      elapsedTimeOfPeriodSeconds: new FormControl(''),
      teamOneScoreAtTimeOfPeriod: new FormControl(''),
      teamTwoScoreAtTimeOfPeriod: new FormControl(''),
      applicableLaw: new FormControl(''),
      proximityMeters: new FormControl(''),
      reportDetails: new FormControl(''),
      citingCommissionerName: new FormControl(''),
      citingCommissionerEmailAddress: new FormControl(''),
      citingCommissionerUnion: new FormControl('')
    });

    if (this.matchId != null) {

      this.form.patchValue({
        citingCommissionerEmailAddress: this.portalUserService.getLoggedInUserEmail()
      })

      this.matchService.getMatch(this.matchId!).subscribe(value => {
        this.match = value;

        this.teams.push(this.match.teamOne);
        this.teams.push(this.match.teamTwo);
      });

      this.lawService.getLaws().subscribe((data: any) => {
        this.laws = data;
      });
    }
  }

  changePlayerList() {
    this.form.get('player')?.setValue(null)

    if (this.form.get('team')?.value === this.match.teamOne) {
      this.players = this.match.teamOne.players;
    } else {
      this.players = this.match.teamTwo.players;
    }
  }

  save(isReadyToEmail: boolean) {

    if (isReadyToEmail) {
      this.form.get('periodOfMatch')?.setValidators([Validators.required]);
      this.form.get('periodOfMatch')?.updateValueAndValidity();
      this.form.get('reportType')?.setValidators([Validators.required]);
      this.form.get('reportType')?.updateValueAndValidity();
      this.form.get('team')?.setValidators([Validators.required]);
      this.form.get('team')?.updateValueAndValidity();
      this.form.get('elapsedTimeOfPeriodMinutes')?.setValidators([Validators.required]);
      this.form.get('elapsedTimeOfPeriodMinutes')?.updateValueAndValidity();
      this.form.get('elapsedTimeOfPeriodSeconds')?.setValidators([Validators.required]);
      this.form.get('elapsedTimeOfPeriodSeconds')?.updateValueAndValidity();
      this.form.get('teamOneScoreAtTimeOfPeriod')?.setValidators([Validators.required]);
      this.form.get('teamOneScoreAtTimeOfPeriod')?.updateValueAndValidity();
      this.form.get('teamTwoScoreAtTimeOfPeriod')?.setValidators([Validators.required]);
      this.form.get('teamTwoScoreAtTimeOfPeriod')?.updateValueAndValidity();
      this.form.get('applicableLaw')?.setValidators([Validators.required]);
      this.form.get('applicableLaw')?.updateValueAndValidity();
      this.form.get('proximityMeters')?.setValidators([Validators.required]);
      this.form.get('proximityMeters')?.updateValueAndValidity();
      this.form.get('reportDetails')?.setValidators([Validators.required]);
      this.form.get('reportDetails')?.updateValueAndValidity();
      this.form.get('citingCommissionerName')?.setValidators([Validators.required]);
      this.form.get('citingCommissionerName')?.updateValueAndValidity();
      this.form.get('citingCommissionerEmailAddress')?.setValidators([Validators.required]);
      this.form.get('citingCommissionerEmailAddress')?.updateValueAndValidity();
      this.form.get('citingCommissionerUnion')?.setValidators([Validators.required]);
      this.form.get('citingCommissionerUnion')?.updateValueAndValidity();

      if (this.form.get('isNonPlayer')?.value) {
        this.form.get('nonPlayerFullName')?.setValidators([Validators.required]);
      } else {
        this.form.get('player')?.setValidators([Validators.required]);
      }
    }

    console.log("Is form valid? " + this.form.valid);
    //Do not apply validation on a save
    if (isReadyToEmail && !this.form.valid) {
      this.handleError("Validation failed", "Please provide valid data in fields.")
      return
    }

    if (this.match.matchType == 'FIFTEEN_A_SIDE') {

      if (this.form.get('periodOfMatch')?.value != null && this.form.get('periodOfMatch')?.value != '' && this.form.get('elapsedTimeOfPeriodMinutes')?.value != null) {

        if (this.form.get('periodOfMatch')?.value == 1 && this.form.get('elapsedTimeOfPeriodMinutes')?.value > 40) {
          this.handleError("Validation failed", "Time of Event Falls Outside of 1st Half (must be less than or equal to 40 minutes)")
          return
        } else if (this.form.get('periodOfMatch')?.value == 2 && (this.form.get('elapsedTimeOfPeriodMinutes')?.value < 40 || this.form.get('elapsedTimeOfPeriodMinutes')?.value > 80)) {
          this.handleError("Validation failed", "Time of Event Falls Outside of 2nd Half (must be more than 40 minutes and less than or equal to 80 minutes)")
          return
        } else if (this.form.get('periodOfMatch')?.value == 3 && this.form.get('elapsedTimeOfPeriodMinutes')?.value < 80) {
          this.handleError("Validation failed", "Time of Event Falls Outside of Extra Time (must be more than 80 minutes)")
          return
        }
      }
    }

    if (this.match.matchType == 'SEVEN_A_SIDE') {

      if (this.form.get('periodOfMatch')?.value != null && this.form.get('periodOfMatch')?.value != '' && this.form.get('elapsedTimeOfPeriodMinutes')?.value != null) {

        if (this.form.get('periodOfMatch')?.value == 1 && this.form.get('elapsedTimeOfPeriodMinutes')?.value > 7) {
          this.handleError("Validation failed", "Time of Event Falls Outside of 1st Half (must be less than or equal to 7 minutes)")
          return
        } else if (this.form.get('periodOfMatch')?.value == 2 && (this.form.get('elapsedTimeOfPeriodMinutes')?.value < 7 || this.form.get('elapsedTimeOfPeriodMinutes')?.value > 14)) {
          this.handleError("Validation failed", "Time of Event Falls Outside of 2nd Half (must be more than 7 minutes and less than or equal to 14 minutes)")
          return
        } else if (this.form.get('periodOfMatch')?.value == 3 && this.form.get('elapsedTimeOfPeriodMinutes')?.value < 14) {
          this.handleError("Validation failed", "Time of Event Falls Outside of Extra Time (must be more than 14 minutes)")
          return
        }
      }
    }

    const emailRegex = new RegExp("[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?");

    if (this.form.get('citingCommissionerEmailAddress')?.value != null
      && !emailRegex.test(this.form.get('citingCommissionerEmailAddress')?.value)) {

      this.handleError("Validation failed", "Email Address is Invalid")
      return
    }

    let createOrUpdateCitingReportRequestDTO: CreateOrUpdateCitingReportRequestDTO = {
      matchId: this.matchId != null ? this.matchId : this.sanctionReport!.matchId,
      half: this.form.get('periodOfMatch')?.value,
      reportType: this.form.get('reportType')?.value,
      teamId: this.form.get('team')?.value != null ? this.form.get('team')?.value.id : null,
      playerId: !this.form.get('isNonPlayer')?.value ? (this.form.get('player')?.value != null ? this.form.get('player')?.value.id : null) : null,
      nonPlayerFullName: this.form.get('isNonPlayer')?.value ? this.form.get('nonPlayerFullName')?.value : null,
      timeOfEvent: this.form.get('elapsedTimeOfPeriodMinutes')?.value + ':' + this.form.get('elapsedTimeOfPeriodSeconds')?.value,
      scoreAtTimeOfEvent: this.form.get('teamOneScoreAtTimeOfPeriod')?.value + '-' + this.form.get('teamTwoScoreAtTimeOfPeriod')?.value,
      lawId: this.form.get('applicableLaw')?.value != null ? this.form.get('applicableLaw')?.value.id : null,
      proximityMetres: this.form.get('proximityMeters')?.value,
      reportDetails: this.form.get('reportDetails')?.value,
      citingCommissionerName: this.form.get('citingCommissionerName')?.value,
      citingCommissionerEmailAddress: this.form.get('citingCommissionerEmailAddress')?.value,
      citingCommissionerUnion: this.form.get('citingCommissionerUnion')?.value,
      readyToEmail: isReadyToEmail
    }

    if (this.isNewCitingReport) {
      this.sanctionService.createCitingReport(createOrUpdateCitingReportRequestDTO).subscribe(() => {
        this.goToMatch(this.matchId!);
        this.dialogRef.close();
      }, (error => {
        console.error("Error calling service: " + JSON.stringify(error));
        //this.handleError(error, "Unable to save");
      }));
    } else {
      this.sanctionService.updateCitingReport(createOrUpdateCitingReportRequestDTO, this.sanctionId!).subscribe(() => {
        this.goToMatch(this.matchId!);
        this.dialogRef.close();
      }, (error => {
        console.error("Error calling service: " + JSON.stringify(error));
      }));
    }
  }

  handleError(error: any, errorMessage: string): void {
    this._snackBar.open(errorMessage, 'OK', {
      horizontalPosition: "center",
      verticalPosition: "top",
    });
  }

  goToMatch(id: number): void {
    console.log("Going to match: " + id);
    this.matchService.getMatch(id)
      .subscribe((data: MatchDTO) => {
          console.log(data);
          localStorage.setItem('matchView', JSON.stringify(data));
          this.router.navigate(['match/', id]);
        }, (error => {
          console.error("Error calling service: " + JSON.stringify(error));
          this.handleError(error, "Unable to get match details");
        })
      );
  }

  allowOnlyNumbers(event: KeyboardEvent): void {
    const charCode = event.which || event.keyCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  }

  cancel() {

    this.dialogRef.close();
  }
}
