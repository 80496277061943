import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {PortalUserDTO} from "../../dtos/PortalUserDTO";
import {PortalUserService} from "../../services/PortalUserService";
import {PortalUserRoleType} from "../../interfaces/PortalUserRoleType";
import {CreatePortalUserRequestDTO} from "../../dtos/CreatePortalUserRequestDTO";

@Component({
  selector: 'app-portal-user-details',
  templateUrl: './portal-user-details.component.html',
  styleUrls: ['./portal_user-details.component.css']
})
export class PortalUserDetailsComponent implements OnInit {
  email?: string;
  portalUserRoleType?: string;
  portalUserId?: number;

  portalUserRoleTypes: PortalUserRoleType[] = [
    {value: 'ADMIN', viewValue: 'Admin'},
    {value: 'USER', viewValue: 'User'}
  ];

  constructor(private portalUserService: PortalUserService,
              private _snackBar: MatSnackBar,
              public dialogRef: MatDialogRef<PortalUserDetailsComponent>) {

  }

  ngOnInit(): void {

    if (localStorage.getItem("portalUserDetails") != null) {
      let portalUser = JSON.parse(localStorage.getItem("portalUserDetails")!);
      this.email = portalUser.email;
      this.portalUserRoleType = portalUser.portalUserRoleType;
      this.portalUserId = portalUser.id;
    }
  }

  save(): void {

    if (this.portalUserId != null) {
      this.update();
    } else {
      this.create();
    }
  }

  create(): void {
    let createPortalUserRequestDTO: CreatePortalUserRequestDTO = {
      email: this.email!,
      portalUserRoleType: this.portalUserRoleType!
    }
    this.portalUserService.createPortalUser(createPortalUserRequestDTO).subscribe(() => {
      this.dialogRef.close();
    }, (error => {
      console.error("Error calling service: " + JSON.stringify(error));
      this.handleError(error, "Unable to create portal user");
    }));
  }

  update(): void {
    let portalUser: PortalUserDTO = {
      id: this.portalUserId!,
      email: this.email!,
      portalUserRoleType: this.portalUserRoleType!
    }
    this.portalUserService.updatePortalUser(this.portalUserId!, portalUser).subscribe(() => {
      this.dialogRef.close();
    }, (error => {
      console.error("Error calling service: " + JSON.stringify(error));
      this.handleError(error, "Unable to update portal user");
    }));
  }

  cancel(): void {
    this.dialogRef.close();
  }

  handleError(error: any, errorMessage: string): void {
    this._snackBar.open(errorMessage, 'OK', {
      horizontalPosition: "center",
      verticalPosition: "top",
    });
  }
}
