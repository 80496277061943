<div class="flex flex-row w-full items-center">
  <div class="basis-1/4">
    <mat-form-field appearance="outline" class="w-full">
      <mat-label>Search</mat-label>
      <input type="search" matInput placeholder="Search" [(ngModel)]="filterCriteria" (keyup)="doFilter()">
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
  </div>
  <div class="basis-1/4 pl-3">
    <mat-form-field appearance="outline" class="w-full">
      <mat-label>Enter a date range</mat-label>
      <mat-date-range-input [rangePicker]="picker" [formGroup]="range">
        <input matStartDate formControlName="start" placeholder="Start date">
        <input matEndDate formControlName="end" placeholder="End date">
      </mat-date-range-input>
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker>
        <mat-date-range-picker-actions>
          <button mat-button matDateRangePickerCancel>Cancel</button>
          <button mat-raised-button color="primary" (click)="doDateFilter()"
                  matDateRangePickerApply>Apply
          </button>
        </mat-date-range-picker-actions>
      </mat-date-range-picker>
    </mat-form-field>
  </div>
  <div class="basis-1/2">
    <button mat-raised-button color="warn" class="btn-4 ml-3 float-left mb-6"
            (click)="resetFilters()" matTooltip="Reset Filters">
      <mat-icon class="btn-mat-icon-white">clear</mat-icon>
    </button>
    <button mat-raised-button color="accent" class="btn-4 ml-3 float-left mb-6"
            (click)="loadTournaments()" matTooltip="Reload Tournaments">
      <mat-icon class="btn-mat-icon-white">refresh</mat-icon>
    </button>
    <button mat-raised-button (click)="createNewTournament()" color="accent" class="float-right btn "
            matTooltip="New Tournament" *ngIf="portalUserService.isAdmin()">New Tournament
    </button>
  </div>
</div>
<div class="pt-5 h-full">
  <table mat-table [dataSource]="datasource" matSort #table>
    <ng-container matColumnDef="rimsEqId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> RIMS EQ ID
        <button mat-raised-button color="accent" class="btn-7"
                (click)="syncRimsTournaments()" matTooltip="Sync Rims Tournaments" *ngIf="portalUserService.isAdmin()">
          <mat-icon class="btn-mat-icon-white">sync</mat-icon>
        </button>
      </th>
      <td mat-cell *matCellDef="let element"> {{ element.tournamentEqId }}</td>
    </ng-container>
    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> TITLE</th>
      <td mat-cell *matCellDef="let element"> {{ element.title }}</td>
    </ng-container>
    <ng-container matColumnDef="startDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> START DATE</th>
      <td mat-cell *matCellDef="let element"> {{ element.startDate | date:'medium' }}</td>
    </ng-container>
    <ng-container matColumnDef="endDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> END DATE</th>
      <td mat-cell *matCellDef="let element"> {{ element.endDate | date:'medium' }}</td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef> ACTIONS</th>
      <td mat-cell *matCellDef="let element">
        <button mat-raised-button color="primary" class="btn-3 mr-3" (click)="editTournamentDetails(element.id)"
                matTooltip="Edit Tournament">
          <mat-icon class="btn-mat-icon-darkblue">edit</mat-icon>
        </button>
        <button mat-raised-button color="primary" class="btn-3 mr-3"
                (click)="deleteTournament(element.id)" matTooltip="Delete tournament"
                *ngIf="portalUserService.isAdmin()">
          <mat-icon class="btn-mat-icon-darkblue">delete</mat-icon>
        </button>
        <button mat-raised-button color="primary" class="btn-3 mr-3" (click)="syncTournament(element.id)"
                [hidden]="element.tournamentEqId === null" matTooltip="Sync tournament from RIMS"
                *ngIf="portalUserService.isAdmin()">
          <mat-icon class="btn-mat-icon-darkblue">sync</mat-icon>
        </button>
        <button mat-raised-button color="primary" class="btn-3 mr-3" (click)="createUserAssignedTournament(element.id)"
                matTooltip="Assign Tournament" *ngIf="portalUserService.isAdmin()">
          <mat-icon class="btn-mat-icon-darkblue">person_add</mat-icon>
        </button>
        <button mat-raised-button color="primary" class="btn-3 mr-3" (click)="editTournamentEmailAddresses(element.id)"
                matTooltip="Add designated reports mailboxes">
          <mat-icon class="btn-mat-icon-darkblue">assessment</mat-icon>
        </button>
        <button mat-raised-button color="primary" class="btn-3 mr-3"
                [routerLink]="['/tournament-team-mappings', element.id]"
                matTooltip="Go to Tournament Team Mappings">
          <mat-icon class="btn-mat-icon-darkblue">open_in_new</mat-icon>
        </button>
        <button mat-raised-button color="primary" class="btn-3 mr-3"
                (click)="tournamentLoginCodesExcel(element.id, element.title)"
                matTooltip="Tournament Login Codes">
          <mat-icon class="btn-mat-icon-darkblue">download</mat-icon>
        </button>
        <button mat-raised-button color="primary" class="btn-3 mr-3" [routerLink]="['/matches', element.id]"
                matTooltip="View Tournament Matches">
          <mat-icon class="btn-mat-icon-darkblue">list</mat-icon>
        </button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator #paginator [pageSizeOptions]="[15,25,50]" [pageSize]="15" showFirstLastButtons></mat-paginator>
</div>
