import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UserAssignedTournamentService} from "../../services/UserAssignedTournamentService";
import {CreateUserAssignedTournamentRequestDTO} from "../../dtos/CreateUserAssignedTournamentRequestDTO";
import {Tournament} from "../../interfaces/Tournament";
import {TournamentsResponseDTO} from "../../dtos/TournamentsResponseDTO";
import {PortalUser} from "../../interfaces/PortalUser";
import {TournamentService} from "../../services/tournament.service";
import {PortalUserService} from "../../services/PortalUserService";
import {PortalUsersResponseDTO} from "../../dtos/PortalUsersResponseDTO";

@Component({
  selector: 'app-user-assigned-tournament-details',
  templateUrl: './user-assigned-tournament-details.component.html',
  styleUrls: ['./user-assigned-tournament-details.component.css']
})
export class UserAssignedTournamentDetailsComponent implements OnInit {
  portalUserId?: number;
  tournamentId?: number;
  portalUsers?: PortalUser[];
  tournaments?: Tournament[];
  selectedPortalUser?: PortalUser;
  selectedTournament?: Tournament;

  constructor(private userAssignedTournamentService: UserAssignedTournamentService,
              private portalUserService: PortalUserService,
              private tournamentService: TournamentService,
              private _snackBar: MatSnackBar,
              public dialogRef: MatDialogRef<UserAssignedTournamentDetailsComponent>) {
  }

  ngOnInit(): void {

    if (localStorage.getItem("portalUserIdToAssign") != null) {
      this.portalUserId = Number(localStorage.getItem("portalUserIdToAssign")!);
    }

    if (localStorage.getItem("tournamentIdToAssign") != null) {
      this.tournamentId = Number(localStorage.getItem("tournamentIdToAssign")!);
    }

    this.loadPortalUsers();
    this.loadTournaments();
  }

  tournamentSelected(): void {

    //Only load portal users if portalUserId is not provided
    if (this.portalUserId == null) {
      this.loadPortalUsers();
    }
  }

  portalUserSelected() {

    //Only load tournaments if tournamentId is not provided
    if (this.tournamentId == null) {
      this.loadTournaments();
    }
  }


  loadTournaments(): void {

    if (this.selectedPortalUser != null) {
      this.userAssignedTournamentService.getAllNotYetAssignedTournamentsForUser(this.selectedPortalUser.id).subscribe((data: TournamentsResponseDTO) => {
        this.tournaments = data.tournaments;
      }, (error => {
        console.error("Error calling service: " + JSON.stringify(error));
        this.handleError(error, "Unable to get tournaments");
      }));
    } else {
      this.tournamentService.getTournaments().subscribe((data: TournamentsResponseDTO) => {
        this.tournaments = data.tournaments;

        if (this.tournamentId != null) {
          this.selectedTournament = this.tournaments.find(t => t.id === this.tournamentId);
        }
      }, (error => {
        console.error("Error calling service: " + JSON.stringify(error));
        this.handleError(error, "Unable to get tournaments");
      }));
    }
  }

  loadPortalUsers(): void {

    if (this.selectedTournament != null) {
      this.userAssignedTournamentService.getAllUnAssignedPortalUsersForTournament(this.selectedTournament.id).subscribe((data: PortalUsersResponseDTO) => {
        this.portalUsers = data.portalUsers;

        if (this.portalUserId != null) {
          this.selectedPortalUser = this.portalUsers.find(t => t.id === this.portalUserId);
        }
      }, (error => {
        console.error("Error calling service: " + JSON.stringify(error));
        this.handleError(error, "Unable to get portal users");
      }));
    } else {
      this.portalUserService.getAllPortalUsers().subscribe((data: PortalUsersResponseDTO) => {
        this.portalUsers = data.portalUsers;

        if (this.portalUserId != null) {
          this.selectedPortalUser = this.portalUsers.find(t => t.id === this.portalUserId);
        }
      }, (error => {
        console.error("Error calling service: " + JSON.stringify(error));
        this.handleError(error, "Unable to get portal users");
      }));
    }
  }

  save(): void {
    let createUserAssignedTournamentRequestDTO: CreateUserAssignedTournamentRequestDTO = {
      portalUserId: this.selectedPortalUser!.id,
      tournamentId: this.selectedTournament!.id
    }

    this.userAssignedTournamentService.createUserAssignedTournament(createUserAssignedTournamentRequestDTO).subscribe(() => {
      this.dialogRef.close();
    }, (error => {
      console.error("Error calling service: " + JSON.stringify(error));
      this.handleError(error, "Unable to save user assigned tournament");
    }));
  }

  cancel(): void {
    this.dialogRef.close();
  }

  handleError(error: any, errorMessage: string): void {
    this._snackBar.open(errorMessage, 'OK', {
      horizontalPosition: "center",
      verticalPosition: "top",
    });
  }
}
