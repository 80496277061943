import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HomeComponent} from './components/home/home.component';
import {MatchesComponent} from './components/matches/matches.component';

import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatNativeDateModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatTableModule} from '@angular/material/table';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ViewMatchComponent} from './components/view-match/view-match.component';
import {MatTabsModule} from '@angular/material/tabs';
import {MatchDetailsComponent} from './components/match-details/match-details.component';
import {MatSelectModule} from '@angular/material/select';
import {DatePipe} from '@angular/common';
import {NgxColorsModule} from 'ngx-colors';
import {MatCardModule} from '@angular/material/card';
import {BreadcrumbModule} from 'xng-breadcrumb';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {OfficialsDetailsComponent} from './components/officials-details/officials-details.component';
import {TeamPlayersComponent} from './components/team-players/team-players.component';
import {PlayerComponent} from './components/player/player.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatchSettingsComponent} from './components/match-settings/match-settings.component';
import {MatchPinsComponent} from './components/match-pins/match-pins.component';
import {MatchTimelineComponent} from './components/match-timeline/match-timeline.component';
import {NewMatchComponent} from './components/new-match/new-match.component';
import {MatStepperModule} from '@angular/material/stepper';
import {TournamentsComponent} from './components/tournaments/tournaments.component';
import {TeamMappingsComponent} from './components/team-mappings/team-mappings.component';
import {OverlayModule} from '@angular/cdk/overlay';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {ProgressSpinnerComponent} from './components/progress-spinner/progress-spinner.component';
import {HttpRequestInterceptor} from './interceptors/HttpRequestInterceptor';
import {OverlayService} from './services/OverlayService';
import {TeamMappingComponent} from './components/team-mapping/team-mapping.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTooltipModule} from '@angular/material/tooltip';
import {LoginComponent} from './components/login/login.component';
import {ReportsComponent} from './components/reports/reports.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {TournamentEmailsComponent} from './components/tournament-emails/tournament-emails.component';
import {MatChipsModule} from "@angular/material/chips";
import {TournamentDetailsComponent} from "./components/tournament-details/tournament-details.component";
import {
  TournamentTeamMappingsComponent
} from "./components/tournament-team-mappings/tournament-team-mappings.component";
import {TournamentTeamMappingComponent} from "./components/tournament-team-mapping/tournament-team-mapping.component";
import {MSAL_INSTANCE, MsalModule, MsalService} from "@azure/msal-angular";
import {IPublicClientApplication, PublicClientApplication} from "@azure/msal-browser";
import {environment} from "../environments/environment";
import {PortalUsersComponent} from "./components/portal-users/portal-users.component";
import {PortalUserDetailsComponent} from "./components/portal-user-details/portal-user-details.component";
import {
  UserAssignedTournamentsComponent
} from "./components/user-assigned-tournaments/user-assigned-tournaments.component";
import {
  UserAssignedTournamentDetailsComponent
} from "./components/user-assigned-tournament-details/user-assigned-tournament-details.component";
import {CitingReportDetailsComponent} from "./components/citing-report-details/citing-report-details.component";
import {MatMenuModule} from "@angular/material/menu";

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: `${environment.clientId}`,
      authority: 'https://login.microsoftonline.com/' + `${environment.tenantId}`,
      redirectUri: `${environment.redirectUri}`,
      postLogoutRedirectUri: `${environment.postLogoutRedirectUri}`
    },
  });
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    MatchesComponent,
    ViewMatchComponent,
    MatchDetailsComponent,
    OfficialsDetailsComponent,
    TeamPlayersComponent,
    PlayerComponent,
    MatchSettingsComponent,
    MatchPinsComponent,
    MatchTimelineComponent,
    NewMatchComponent,
    TournamentsComponent,
    PortalUsersComponent,
    PortalUserDetailsComponent,
    UserAssignedTournamentsComponent,
    UserAssignedTournamentDetailsComponent,
    TeamMappingsComponent,
    ProgressSpinnerComponent,
    TeamMappingComponent,
    TournamentTeamMappingsComponent,
    TournamentTeamMappingComponent,
    TournamentDetailsComponent,
    LoginComponent,
    ReportsComponent,
    TournamentEmailsComponent,
    CitingReportDetailsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatTableModule,
    MatSnackBarModule,
    HttpClientModule,
    MatTabsModule,
    MatSelectModule,
    ReactiveFormsModule,
    NgxColorsModule,
    MatCardModule,
    BreadcrumbModule,
    NgxMaterialTimepickerModule,
    MatSlideToggleModule,
    MatStepperModule,
    MatProgressSpinnerModule,
    OverlayModule,
    MatDialogModule,
    MatTooltipModule,
    MatExpansionModule,
    MsalModule,
    MatChipsModule,
    MatMenuModule,
  ],
  providers: [DatePipe,
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true,
    },
    MsalService,
    OverlayService],
  bootstrap: [AppComponent]
})
export class AppModule {
}
