import {Component} from '@angular/core';
import {MsalService} from "@azure/msal-angular";
import {Router} from "@angular/router";
import {PortalUserService} from "../../services/PortalUserService";
import {MatchDTO} from "../../dtos/MatchDTO";
import {PortalUserDTO} from "../../dtos/PortalUserDTO";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {

  constructor(private msalService: MsalService, private portalUserService: PortalUserService, private router: Router) {
  }

  ngOnInit(): void {

    if (this.isLoggedIn()) {
      this.router.navigate(['/matches']);
    }

    (async () => {
      try {
        await this.msalService.instance.initialize();
        this.msalService.instance.handleRedirectPromise().then(
          res => {
            if (res != null && res.account != null) {
              this.msalService.instance.setActiveAccount(res.account)
              const account = this.msalService.instance.getActiveAccount();

              const email = account!.idTokenClaims?.preferred_username;

              this.portalUserService.getPortalUser(email!).subscribe((data: PortalUserDTO) => {
                  localStorage.setItem('portalUser', JSON.stringify(data));
                },
                (error) => {
                  console.error(JSON.stringify(error));
                });

              this.router.navigate(['/matches']);
            }
          }
        );
      } catch (error) {
        console.error('MSAL initialization failed', error);
      }
    })();
  }

  login() {
    this.msalService.loginRedirect();
  }

  isLoggedIn(): boolean {
    return this.msalService.instance.getActiveAccount() != null
  }
}
