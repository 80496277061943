import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {PortalUserDTO} from "../dtos/PortalUserDTO";
import {PortalUsersResponseDTO} from "../dtos/PortalUsersResponseDTO";
import {PortalUser} from "../interfaces/PortalUser";
import {CreatePortalUserRequestDTO} from "../dtos/CreatePortalUserRequestDTO";

@Injectable({
  providedIn: 'root'
})
export class PortalUserService {

  url = environment.apiUrl;

  constructor(private http: HttpClient) {
  }

  getPortalUser(email: string) {
    return this.http.get<PortalUserDTO>(this.url + "portal-users/" + encodeURIComponent(email));
  }

  getAllPortalUsers() {
    return this.http.get<PortalUsersResponseDTO>(this.url + "portal-users");
  }

  updatePortalUser(id: number, portalUser: PortalUser) {
    return this.http.put(this.url + "portal-users/" + id, portalUser);
  }

  createPortalUser(createPortalUserRequestDTO: CreatePortalUserRequestDTO) {
    return this.http.post(this.url + "portal-users", createPortalUserRequestDTO);
  }

  getUserRole() {
    let portalUser: PortalUser = JSON.parse(localStorage.getItem('portalUser')!);
    return portalUser.portalUserRoleType;
  }

  getLoggedInUserEmail() {
    let portalUser: PortalUser = JSON.parse(localStorage.getItem('portalUser')!);
    return portalUser.email;
  }
}
