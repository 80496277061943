<form [formGroup]="officialsSubForm">
    <div class="grid grid-cols-2 gap-2 pt-5">
        <mat-form-field appearance="outline">
            <mat-label>Referee</mat-label>
            <input matInput formControlName="referee" placeholder="Referee">
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Assistant Referee</mat-label>
            <input matInput formControlName="assistantReferee1" placeholder="Assistant Referee">
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Assistant Referee</mat-label>
            <input matInput formControlName="assistantReferee2" placeholder="Assistant Referee">
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Match Official 4</mat-label>
            <input matInput formControlName="matchOfficial4" placeholder="Match Official 4">
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Match Official 5</mat-label>
            <input matInput formControlName="matchOfficial5" placeholder="Match Official 5">
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Match Official 6</mat-label>
            <input matInput formControlName="matchOfficial6" placeholder="Match Official 6">
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>TMO</mat-label>
            <input matInput formControlName="tmo" placeholder="TMO">
        </mat-form-field>
    </div>
    <!-- <button mat-raised-button color="primary" class="btn">Save</button> -->
</form>