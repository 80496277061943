<div class="grid grid-cols-1 gap-2" [formGroup]="matchSettingSubForm">
    <div class="h-2"></div>
    <mat-slide-toggle formControlName="extraTimeAllowed">Extra time allowed?</mat-slide-toggle>
    <div class="h-4"></div>
</div>
<div class="grid grid-cols-2 gap-2" [formGroup]="matchSettingSubForm">
    <mat-form-field appearance="outline">
        <mat-label>Time per half</mat-label>
        <input type="number" matInput formControlName="timePerHalf" placeholder="Time per half" required>
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>Half time length</mat-label>
        <input type="number" matInput formControlName="halfTimeLength" placeholder="Half time length" required>
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>Extra Time - Time per half</mat-label>
        <input type="number" matInput formControlName="extraTimePerHalf" placeholder="Extra Time - Time per half"
            required>
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>Yellow Card Time</mat-label>
        <input type="number" matInput formControlName="yellowCardTime" placeholder="Yellow Card Time" required>
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>Blood Bin Time</mat-label>
        <input type="number" matInput formControlName="bloodBinTime" placeholder="Blood Bin Time" required>
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>Head Injury Assessment Time</mat-label>
        <input type="number" matInput formControlName="hiaTime" placeholder="Head Injury Assessment Time" required>
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>Red Card Length</mat-label>
        <input type="number" matInput formControlName="redCardLength" placeholder="Leave 0 if player cannot return"
            required>
    </mat-form-field>
</div>
<!-- <button mat-raised-button color="primary" class="btn">Save</button> -->