<mat-card>
  <mat-card-header>
    <div class="flex flex-row w-full">
      <div class="flex flex-col w-full">
        <div class="flex flex-row justify-center items-center gap-4">
          <div class="font-semibold text-lg">Create new team mapping</div>
        </div>
      </div>
    </div>
  </mat-card-header>
  <mat-card-content style="min-width: 500px">
    <div class="grid grid-cols-1 gap-1 p-5">
      <mat-form-field class="example-chip-list">
        <mat-chip-grid #chipGrid aria-label="Enter emails">
          <mat-chip-row *ngFor="let email of teamEmails; let i = index" (removed)="remove(email)" [editable]="true"
                        (edited)="edit(email, $event)" [aria-description]="'press enter to edit ' + email">
            {{ email }}
            <button matChipRemove [attr.aria-label]="'remove ' + email">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip-row>
          <input placeholder="New email..." [matChipInputFor]="chipGrid"
                 [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
                 (matChipInputTokenEnd)="add($event)"/>
        </mat-chip-grid>
      </mat-form-field>

      <div class="grid grid-cols-2 gap-3">
        <button mat-raised-button (click)="save()" color="accent">Save</button>
        <button mat-raised-button (click)="cancel()" color="warn">Cancel</button>
      </div>
    </div>
  </mat-card-content>
</mat-card>
