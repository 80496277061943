<mat-card>
    <mat-card-header>
        <div class="flex flex-row w-full">
            <div class="flex flex-col w-full">
                <div class="flex flex-row justify-center items-center gap-4">
                    <div class="font-semibold text-lg">Create new match</div>
                </div>
            </div>
        </div>
    </mat-card-header>
    <mat-card-content>
        <div class="w-full flex-grow justify-center">
            <mat-stepper orientation="horizontal" [linear]="isLinear" #stepper>
                <mat-step [stepControl]="firstFormGroup">
                    <form [formGroup]="firstFormGroup" (ngSubmit)="matchDetailsSubmit()">
                        <ng-template matStepLabel>Match Details</ng-template>
                        <app-match-details></app-match-details>
                        <div>
                            <button mat-raised-button color="primary" class="btn" matStepperNext>Next</button>
                        </div>
                    </form>
                </mat-step>
                <mat-step [stepControl]="secondFormGroup">
                    <form [formGroup]="secondFormGroup" (ngSubmit)="officialsSummit()">
                        <ng-template matStepLabel>Officials</ng-template>
                        <app-officials-details></app-officials-details>
                        <div class="flex space-x-4">
                            <button mat-raised-button color="accent" class="btn" matStepperPrevious>Back</button>
                            <button mat-raised-button color="primary" class="btn" matStepperNext>Next</button>
                        </div>
                    </form>
                </mat-step>
                <mat-step [stepControl]="homeTeamFormGroup">
                    <form [formGroup]="homeTeamFormGroup" (ngSubmit)="homeTeamSubmit()">
                        <ng-template matStepLabel>Home Team</ng-template>
                        <app-team-players [players]="homeTeamPlayers"></app-team-players>
                        <div class="flex space-x-4">
                            <button mat-raised-button color="accent" class="btn" matStepperPrevious>Back</button>
                            <button mat-raised-button color="primary" class="btn" matStepperNext>Next</button>
                        </div>
                    </form>
                </mat-step>
                <mat-step [stepControl]="visitingTeamFormGroup">
                    <form [formGroup]="visitingTeamFormGroup" (ngSubmit)="visitingTeamSubmit()">
                        <ng-template matStepLabel>Visiting Team</ng-template>
                        <app-team-players [players]="awayTeamPlayers"></app-team-players>
                        <div class="flex space-x-4">
                            <button mat-raised-button color="accent" class="btn" matStepperPrevious>Back</button>
                            <button mat-raised-button color="primary" class="btn" matStepperNext>Next</button>
                        </div>
                    </form>
                </mat-step>
                <mat-step [stepControl]="gameSettingsFromGroup">
                    <form [formGroup]="gameSettingsFromGroup" (ngSubmit)="matchSettingsSubmit()">
                        <ng-template matStepLabel>Match Settings</ng-template>
                        <app-match-settings></app-match-settings>
                        <div class="flex space-x-4">
                            <button mat-raised-button color="accent" class="btn" matStepperPrevious>Back</button>
                            <button mat-raised-button color="primary" class="btn" matStepperNext>Next</button>
                        </div>
                    </form>
                </mat-step>
                <mat-step>
                    <ng-template matStepLabel>Done</ng-template>
                    <p>You are now done.</p>
                    <div class="flex space-x-4">
                        <button mat-raised-button color="accent" class="btn" matStepperPrevious>Back</button>
                        <button mat-raised-button color="primary" class="btn" (click)="createMatch()">Save</button>
                        <button mat-raised-button color="warn" class="btn" (click)="stepper.reset()">Cancel</button>
                    </div>
                </mat-step>
            </mat-stepper>
        </div>
    </mat-card-content>
</mat-card>