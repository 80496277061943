import { OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { delay } from 'rxjs';
import { LoadingService } from 'src/app/services/LoadingService';
import { OverlayService } from 'src/app/services/OverlayService';

@Component({
  selector: 'app-progress-spinner',
  templateUrl: './progress-spinner.component.html',
  styleUrls: ['./progress-spinner.component.css']
})
export class ProgressSpinnerComponent implements OnInit {
  title = 'angular-boilerplate';
  loading: boolean = false;

  private overlayRef!: OverlayRef;
  private progressSpinnerOverlayConfig!: OverlayConfig;

  constructor(
    private _loading: LoadingService,
    private vcRef: ViewContainerRef, private overlayService: OverlayService
  ) { }

  ngOnInit() {
    this.listenToLoading();
    this.progressSpinnerOverlayConfig = {
      hasBackdrop: true
    };
    this.overlayRef = this.overlayService.createOverlay(this.progressSpinnerOverlayConfig);
  }

  listenToLoading(): void {
    this._loading.loadingSub
      .pipe(delay(0))
      .subscribe((loading) => {
        this.loading = loading;
      });
  }
}
