import {Component, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DomSanitizer} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';
import {MatchService} from 'src/app/services/MatchService';
import {TournamentService} from 'src/app/services/tournament.service';
import {Tournament} from "../../interfaces/Tournament";
import {TournamentDTO} from "../../dtos/TournamentDTO";

@Component({
  selector: 'app-tournament-details',
  templateUrl: './tournament-details.component.html',
  styleUrls: ['./tournament-details.component.css']
})
export class TournamentDetailsComponent implements OnInit {
  title!: string;
  titleAbbreviation!: string;
  startDate!: Date;
  endDate!: Date;
  tournamentId?: number;

  constructor(private fb: FormBuilder,
              private route: ActivatedRoute,
              private tournamentService: TournamentService,
              private _sanitizer: DomSanitizer,
              private matchService: MatchService,
              private _snackBar: MatSnackBar,
              public dialogRef: MatDialogRef<TournamentDetailsComponent>) {

  }

  ngOnInit(): void {
    if (localStorage.getItem("tournament") != null) {
      let tournament = JSON.parse(localStorage.getItem("tournament")!);
      this.title = tournament.title;
      this.titleAbbreviation = tournament.titleAbbreviation;
      this.startDate = tournament.startDate;
      this.endDate = tournament.endDate;
      this.tournamentId = tournament.id;
    }
  }

  save(): void {
    if (this.tournamentId != null) {
      this.update();
    } else {
      this.create();
    }
  }

  update(): void {
    let tournament: TournamentDTO = {
      id: this.tournamentId!,
      title: this.title,
      titleAbbreviation: this.titleAbbreviation,
      startDate: this.startDate,
      endDate: this.endDate
    }
    this.tournamentService.updateTournament(this.tournamentId!, tournament).subscribe(() => {
      this.dialogRef.close();
    }, (error => {
      console.error("Error calling service: " + JSON.stringify(error));
      this.handleError(error, "Unable to update tournament");
    }));
  }

  create(): void {
    let tournament: TournamentDTO = {
      title: this.title,
      titleAbbreviation: this.titleAbbreviation,
      startDate: this.startDate,
      endDate: this.endDate
    }

    this.tournamentService.createTournament(tournament).subscribe(() => {
      this.dialogRef.close();
    }, (error => {
      console.error("Error calling service: " + JSON.stringify(error));
      this.handleError(error, "Unable to save tournament");
    }));
  }

  cancel(): void {
    this.dialogRef.close();
  }

  handleError(error: any, errorMessage: string): void {
    this._snackBar.open(errorMessage, 'OK', {
      horizontalPosition: "center",
      verticalPosition: "top",
    });
  }
}
