import {Injectable} from "@angular/core";
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class LawService {

  url = environment.apiUrl;

  constructor(
    private http: HttpClient
  ) {
  }

  getLaws() {
    return this.http.get<any>(this.url + "laws/all");
  }
}
