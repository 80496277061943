import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {ActivatedRoute, Router} from '@angular/router';
import {MatchDTO} from 'src/app/dtos/MatchDTO';
import {DateRange} from 'src/app/interfaces/DateRange';
import {Match} from 'src/app/interfaces/Match';
import {MatchService} from 'src/app/services/MatchService';
import {ReportingService} from 'src/app/services/ReportingService';
import {TournamentService} from 'src/app/services/tournament.service';
import {Tournament} from "../../interfaces/Tournament";
import {TournamentsResponseDTO} from "../../dtos/TournamentsResponseDTO";
import {PortalUserService} from "../../services/PortalUserService";
import {MatDialog} from "@angular/material/dialog";
import {CitingReportDetailsComponent} from "../citing-report-details/citing-report-details.component";


@Component({
  selector: 'app-matches',
  templateUrl: './matches.component.html',
  styleUrls: ['./matches.component.css']
})
export class MatchesComponent implements OnInit, AfterViewInit {

  filterCriteria?: string;
  datasource: any;
  matches: Match[] = [];
  matchList: MatchDTO[] = [];
  displayedColumns?: string[];
  @ViewChild('paginator') paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });
  totalElements!: number;
  pageNumber: number = 0;
  pageSize: number = 50;
  tournamentId?: Number | null;
  tournaments?: Tournament[];

  constructor(
    private matchService: MatchService,
    protected portalUserService: PortalUserService,
    private route: ActivatedRoute,
    private router: Router,
    private _snackBar: MatSnackBar,
    private tournamentService: TournamentService,
    private reportingService: ReportingService,
    public dialog: MatDialog
  ) {
  }

  ngAfterViewInit(): void {

  }

  ngOnInit(): void {
    this.displayedColumns = ['id', 'homeTeam', 'matchScore', 'visitingTeam', 'matchDate', 'tournament', 'matchStatus', 'masterCode', 'actions'];

    //Remove masterCode column for CITING role, as they do not have access to it.
    if (this.portalUserService.getUserRole() === 'CITING') {
      this.displayedColumns = this.displayedColumns.filter(col => col !== 'masterCode');
    }

    this.tournamentService.getTournaments().subscribe((data: TournamentsResponseDTO) => {
      this.tournaments = data.tournaments;
    }, (error => {
      console.error("Error calling service: " + JSON.stringify(error));
      this.handleError(error, "Unable to get tournaments");
    }));

    localStorage.removeItem("matchView");
    this.loadMatches();
    this.datasource.sort = this.sort;
    this.datasource.paginator = this.paginator;
  }

  doFilter() {
    this.datasource.filter = this.filterCriteria!.trim().toLocaleLowerCase();
  }

  doFilterByTournament() {
    this.loadMatches();
  }

  doDateFilter() {
    let dr: DateRange = this.range.value;
    console.log(dr);
    let fd = this.matches.filter(value => new Date(value.matchDate) >= new Date(dr.start!) && new Date(value.matchDate) <= new Date(dr.end!));
    console.log(fd);
    this.datasource = new MatTableDataSource(fd);
  }

  resetFilters() {
    this.tournamentId = null;
    this.range.reset();
    this.filterCriteria = "";
    this.loadMatches();
  }

  getSanitizedImageWithName(imageName: String | undefined) {
    return "assets/images/flags/" + imageName
  }

  nextPage(event: PageEvent) {
    this.pageNumber = event.pageIndex;
    this.pageSize = event.pageSize;
    console.log(this.pageNumber);
    console.log(this.pageSize);
    this.loadMatches();
  }

  loadMatches() {
    const routeParams = this.route.snapshot.paramMap;

    if (routeParams.get("id") != null) {
      console.log("Matches by tournamentId " + routeParams.get("id"));
      this.tournamentId = Number(routeParams.get("id")!);
    }

    this.matches = [];
    this.matchService.getMatches(this.tournamentId!, this.pageNumber, this.pageSize).subscribe((data: any) => {
      this.matchList = data['content'];
      this.totalElements = data['totalElements'];
      console.log(this.totalElements);
      for (let match in this.matchList) {
        const m = this.matchList[match];
        this.matches.push({
          matchDate: m.matchDate,
          tournament: m.tournament,
          id: m.id,
          homeTeam: m.teamOne.name,
          homeTeamColor: m.teamOne.jerseyColor,
          visitingTeam: m.teamTwo.name,
          visitingTeamColor: m.teamTwo.jerseyColor,
          teamOneScore: m.teamOneScore,
          teamTwoScore: m.teamTwoScore,
          venue: m.stadium,
          status: m.matchStatus.replace("_", " "),
          country: m.country,
          conditions: m.conditions,
          crowdSize: m.crowdSize,
          teamSize: 0,
          matchType: m.matchType,
          substitutionRule: m.substitutionRule,
          rimsId: m.rimsId,
          visitingTeamBadge: m.teamTwo.teamBadge,
          homeTeamBadge: m.teamOne.teamBadge,
          masterPin: m.masterPin,
          hiddenInApp: m.hiddenInApp
        });
      }
      this.matches.sort((a, b) => (a.matchDate! > b.matchDate! ? -1 : 1))

      this.datasource = new MatTableDataSource(this.matches);
      console.log("matches: " + this.matches);
    }, (error => {
      console.error("Error calling service: " + JSON.stringify(error));
      this.handleError(error, "Unable to get matches");
    }));
  }

  goToMatch(id: number): void {
    console.log("Going to match: " + id);
    this.matchService.getMatch(id)
      .subscribe((data: MatchDTO) => {
          console.log(data);
          localStorage.setItem('matchView', JSON.stringify(data));
          this.router.navigate(['match/', id]);
        }, (error => {
          console.error("Error calling service: " + JSON.stringify(error));
          this.handleError(error, "Unable to get match details");
        })
      );
  }

  showHideFromApp(id: number, visible: boolean): void {
    console.log("updating match: " + id + " to status " + visible);
    this.matchService.showHideMatch(id, visible).subscribe(() => {
      this.loadMatches();
    }, (error => {
      console.error("Error calling service: " + JSON.stringify(error));
      this.handleError(error, "Unable to update match");
    }))
  }

  resetMatch(id: number): void {
    console.log("Resetting match: " + id)
    this.matchService.resetMatch(id)
      .subscribe(() => {
          this.handleError(null, "Match reset");
        }, (error => {
          console.error("Error calling service: " + JSON.stringify(error));
          this.handleError(error, "Unable to get match events");
        })
      )
  }

  deleteMatch(id: number): void {
    if (confirm("Are you sure to delete the match?")) {
      console.log("Match clicked: " + id);
      this.matchService.deleteMatch(id)
        .subscribe(() => {
          this.loadMatches();
        }, error => {
          if (error.status === 412) {
            this.handleError(error, "Match is in progress, and cannot be deleted");
          } else {
            console.error("Error calling service: " + JSON.stringify(error));
            this.handleError(error, "Unable to delete match");
          }
        });
    }
  }

  syncMatch(eqId: string) {
    this.tournamentService.syncMatch(eqId).subscribe(() => {
      this.loadMatches();
    }, error => {
      if (error.status === 412) {
        this.handleError(error, "Match cannot be synced with RIMS");
      } else {
        console.error("Error calling service: " + JSON.stringify(error));
        this.handleError(error, "Unable to sync match with RIMS");
      }
    });
  }

  addNewReport(id: number): void {
    localStorage.setItem('addReportMatchId', id.toString());
    console.log(localStorage.getItem('addReportMatchId'))
    const dialogRef = this.dialog.open(CitingReportDetailsComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      localStorage.removeItem('addReportMatchId');
    });
  }

  downloadOnArrivialTeamSheet(id: number, isHomeTeam: boolean) {
    this.matchService.getMatch(id)
      .subscribe((data: MatchDTO) => {
        if (isHomeTeam) {
          this.reportingService.getOnArrivialTeamSheet(id, data.teamOne.id!).subscribe((response) => {
            const downloadLink = document.createElement('a');
            downloadLink.href = URL.createObjectURL(new Blob([response.body], {type: response.body.type}));

            const contentDisposition = response.headers.get('content-disposition');
            const fileName = data.teamOne.name + " Team Sheet.pdf";
            downloadLink.download = fileName;
            downloadLink.click();
          })
        } else {
          this.reportingService.getOnArrivialTeamSheet(id, data.teamTwo.id!).subscribe((response) => {
            const downloadLink = document.createElement('a');
            downloadLink.href = URL.createObjectURL(new Blob([response.body], {type: response.body.type}));

            const contentDisposition = response.headers.get('content-disposition');
            const fileName = data.teamTwo.name + " Team Sheet.pdf";
            downloadLink.download = fileName;
            downloadLink.click();
          })
        }
      });

  }

  downloadMatchReport(id: number) {
    this.matchService.getMatch(id)
      .subscribe((data: MatchDTO) => {
        this.reportingService.getMatchReport(id).subscribe((response) => {
          const downloadLink = document.createElement('a');
          downloadLink.href = URL.createObjectURL(new Blob([response.body], {type: response.body.type}));

          const contentDisposition = response.headers.get('content-disposition');
          const fileName = data.id + "_" + data.teamOne.name + "_VS_" + data.teamTwo.name + "_Match_Report.pdf";
          downloadLink.download = fileName;
          downloadLink.click();
        })
      });
  }

  handleError(error: any, errorMessage: string): void {
    this._snackBar.open(errorMessage, 'OK', {
      horizontalPosition: "center",
      verticalPosition: "top",
    });
  }

}
