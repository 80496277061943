<div class="flex flex-row w-full items-center">
    <div class="basis-1/4">
        <mat-form-field appearance="outline" class="w-full">
            <mat-label>Search</mat-label>
            <input type="search" matInput placeholder="Search" [(ngModel)]="filterCriteria" (keyup)="doFilter()">
            <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
    </div>
    <div class="basis-3/4">
        <button mat-raised-button color="warn" class="btn-4 ml-3 float-left mb-6"
            (click)="resetFilters()" matTooltip="Clear filters"><mat-icon class="btn-mat-icon-white">clear</mat-icon></button>
        <button mat-raised-button color="accent" class="btn-4 ml-3 float-left mb-6"
                (click)="loadTournamentTeamMappings()" matTooltip="Reload mappings"><mat-icon class="btn-mat-icon-white">refresh</mat-icon></button>
    </div>
</div>
<div class="pt-5 h-full">
    <table mat-table [dataSource]="datasource" matSort #table>
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
            <td mat-cell *matCellDef="let element"> {{element.id}} </td>
        </ng-container>
        <ng-container matColumnDef="tournamentName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> TOURNAMENT NAME </th>
            <td mat-cell *matCellDef="let element"> {{element.tournament.title}} </td>
        </ng-container>
        <ng-container matColumnDef="teamName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> TEAM NAME </th>
            <td mat-cell *matCellDef="let element"> {{element.teamName}} </td>
        </ng-container>
        <ng-container matColumnDef="teamEmails">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> TEAM EMAILS </th>
            <td mat-cell *matCellDef="let element"> {{element.teamEmails}} </td>
        </ng-container>
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> ACTIONS </th>
            <td mat-cell *matCellDef="let element">
                <button mat-raised-button color="primary" class="btn-3"
                    (click)="editTournamentTeamMapping(element.id)"
                    [disabled]="element.rimsId === null" matTooltip="Edit mapping"><mat-icon class="btn-mat-icon-darkblue">edit</mat-icon></button>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator #paginator [pageSizeOptions]="[15,25,50]" [pageSize]="15" showFirstLastButtons></mat-paginator>
</div>
