import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {UserAssignedTournamentsResponseDTO} from "../dtos/UserAssignedTournamentsResponseDTO";
import {CreateUserAssignedTournamentRequestDTO} from "../dtos/CreateUserAssignedTournamentRequestDTO";
import {TournamentsResponseDTO} from "../dtos/TournamentsResponseDTO";
import {PortalUsersResponseDTO} from "../dtos/PortalUsersResponseDTO";

@Injectable({
  providedIn: 'root'
})
export class UserAssignedTournamentService {

  url = environment.apiUrl;

  constructor(private http: HttpClient) {
  }

  getAllUserAssignedTournaments(portalUserId: number) {
    return this.http.get<UserAssignedTournamentsResponseDTO>(this.url + "user-assigned-tournaments/" + portalUserId);
  }

  createUserAssignedTournament(createUserAssignedTournamentRequest: CreateUserAssignedTournamentRequestDTO) {
    return this.http.post(this.url + "user-assigned-tournaments/", createUserAssignedTournamentRequest);
  }

  deleteUserAssignedTournament(userAssignedTournamentId: number) {
    return this.http.delete(this.url + "user-assigned-tournaments/" + userAssignedTournamentId);
  }

  getAllNotYetAssignedTournamentsForUser(portalUserId: number) {
    return this.http.get<TournamentsResponseDTO>(this.url + "user-assigned-tournaments/tournaments-not-yet-assigned/" + portalUserId);
  }

  getAllUnAssignedPortalUsersForTournament(tournamentId: number) {
    return this.http.get<PortalUsersResponseDTO>(this.url + "user-assigned-tournaments/portal-users-not-yet-assigned/" + tournamentId);
  }
}
