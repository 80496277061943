<!-- <div class="w-full flex justify-center grid grid-cols-1 gap-4 pt-5">
    <div class="grid grid-cols-2 gap-2" [formGroup]="matchPinsGroup">
        <mat-form-field appearance="outline">
            <mat-label>Match Official (6)</mat-label>
            <input matInput placeholder="Match Official (6)" formControlName="matchOfficial" required readonly>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Home Team Official (4)</mat-label>
            <input matInput placeholder="Home Team Official (4)" formControlName="homeTeamOfficial" required readonly>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Visiting Team Official (5)</mat-label>
            <input matInput placeholder="Visiting Team Official (5)" formControlName="visitingTeamOfficial" required
                readonly>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Home Team Manager</mat-label>
            <input matInput placeholder="Home Team Manager" formControlName="homeTeamManager" required readonly>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Visiting Team Manager</mat-label>
            <input matInput placeholder="Visiting Team Manager" formControlName="visitingTeamManager" required readonly>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>TMO (Read-Only)</mat-label>
            <input matInput placeholder="TMO (Read-Only)" formControlName="tmo" required readonly>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Broadcast (Read-Only)</mat-label>
            <input matInput placeholder="Broadcast (Read-Only)" formControlName="broadcast" required readonly>
        </mat-form-field>
    </div>
</div> -->
<div class="pt-5 h-full">
  <table mat-table [dataSource]="datasource" matSort #table>
    <ng-container matColumnDef="label">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> LABEL.</th>
      <td mat-cell *matCellDef="let element"> {{ element.label }}</td>
    </ng-container>
    <ng-container matColumnDef="role">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> ROLE</th>
      <td mat-cell *matCellDef="let element"> {{ element.role }}</td>
    </ng-container>
    <ng-container matColumnDef="code">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> CODE</th>
      <td mat-cell *matCellDef="let element"> {{ element.code }}</td>
    </ng-container>
    <ng-container matColumnDef="loggedIn">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> LOGGED IN</th>
      <td mat-cell *matCellDef="let element"> {{ element.loggedIn === true ? 'True' : 'False' }}
        <button mat-raised-button color="primary" class="btn-3" (click)="logoutUser(element.code)"
                matTooltip="Logout User" [hidden]="element.loggedIn != true">
          <mat-icon class="btn-mat-icon-darkblue">logout</mat-icon>
        </button>
      </td>
    </ng-container>
    <!-- <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef> ACTIONS </th>
        <td mat-cell *matCellDef="let element">
            <button mat-raised-button color="primary" class="btn-3 mr-3" (click)="goToMatch(element.id)"
                matTooltip="View match"><mat-icon class="btn-mat-icon-darkblue">visibility</mat-icon></button>
            <button mat-raised-button color="primary" class="btn-3 mr-3" (click)="resetMatch(element.id)"
                matTooltip="Reset match"><mat-icon class="btn-mat-icon-darkblue">refresh</mat-icon></button>
            <button mat-raised-button color="primary" class="btn-3 mr-3" (click)="deleteMatch(element.id)"
                matTooltip="Delete match"><mat-icon class="btn-mat-icon-darkblue">delete</mat-icon></button>
            <button mat-raised-button color="primary" class="btn-3 mr-3" (click)="syncMatch(element.rimsId)"
                [disabled]="element.rimsId === null" matTooltip="Sync match with RIMS"><mat-icon
                    class="btn-mat-icon-darkblue">sync</mat-icon></button>
            <button mat-raised-button color="primary" class="btn-3" (click)="showHideFromApp(element.id, !element.hiddenInApp)"
                matTooltip="Show / Hide in App"><mat-icon *ngIf="element.hiddenInApp == true" class="btn-mat-icon-darkblue">publish</mat-icon><mat-icon *ngIf="element.hiddenInApp == false" class="btn-mat-icon-darkblue">stop</mat-icon></button>
        </td>
    </ng-container> -->
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <!-- <mat-paginator #paginator [pageSizeOptions]="[15,25,50]" [pageSize]="pageSize" [length]="totalElements"
      (page)="nextPage($event)" showFirstLastButtons></mat-paginator> -->
</div>
