import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { GetMatchPinsResponseDto } from 'src/app/dtos/GetMatchPinsResponseDto';
import { MatchPin } from 'src/app/interfaces/MatchPin';
import { MatchService } from 'src/app/services/MatchService';

@Component({
  selector: 'app-match-pins',
  templateUrl: './match-pins.component.html',
  styleUrls: ['./match-pins.component.css']
})
export class MatchPinsComponent implements OnInit {
  filterCriteria?: string;
  datasource: any;
  displayedColumns: string[] = ['label', 'role', 'code', 'loggedIn'];
  matchPinsGroup!: FormGroup;
  matchPins?: GetMatchPinsResponseDto;
  emailAddresses?: string;
  matchId?: number;
  loading = false;
  matchPinList: MatchPin[] = []

  constructor(private fb: FormBuilder,
    private route: ActivatedRoute,
    private matchService: MatchService,
    private _snackBar: MatSnackBar) {

  }

  ngOnInit(): void {
    // this.matchPinsGroup = this.fb.group({
    //   matchOfficial: ['', Validators.required],
    //   homeTeamOfficial: ['', Validators.required],
    //   visitingTeamOfficial: ['', Validators.required],
    //   homeTeamManager: ['', Validators.required],
    //   visitingTeamManager: ['', Validators.required],
    //   tmo: ['', Validators.required],
    //   broadcast: ['', Validators.required]
    // });

    const routeParams = this.route.snapshot.paramMap;
    if (routeParams.get("id") != null) {
      this.matchId = Number(routeParams.get("id"));

      this.matchService.getMatchPins(this.matchId)
      .subscribe((data: GetMatchPinsResponseDto) => {
          console.log(data);
          this.matchPins = data;
          var roNum = 0;
          for (let pin in this.matchPins.accessDetails) {

            const m = this.matchPins.accessDetails[pin];
            const c = m.code;
            var r = m.role;
            var l = "";
            if (r == "Home Team Manager") {
              l = "Team A Team Manager";
              r = "Team A Manager";
            } else if (r == "Visiting Team Manager") {
              l = "Team B Team Manager";
              r = "Team B Manager";
            } else if (r == "Match Official (6)") {
              l = "Technical Zone Manager";
              r = "Number 6";
            } else if (r == "Team Official (4)") {
              l = "Team A Sub Controller";
              r = "Number 4";
            } else if (r == "Team Official (5)") {
              l = "Team B Sub Controller";
              r = "Number 5";
            } else if (r == "TMO") {
              l = "TMO";
              r = "TMO";
            } else if (r == "Broadcast") {
              l = "Broadcast";
              r = "Read Only";
            } else if (r == "Read-Only") {
              roNum++;
              if (roNum == 1) {
                l = "Sports Presentation";
                r = "Read Only";
              } else if (roNum == 2) {
                l = "Stats Provider 01";
                r = "Read Only";
              } else if (roNum == 3) {
                l = "Stats Provider 02";
                r = "Read Only";
              } else if (roNum == 4) {
                l = "MTO";
                r = "Read Only";
              } else {
                l = "Read Only " + roNum;
                r = "Read Only";
              }
            }
            this.matchPinList!.push({
              code: c,
              label: l,
              role: r,
              loggedIn: m.loggedIn
            })
          }
          this.datasource = new MatTableDataSource(this.matchPinList);
          // this.matchPinsGroup.patchValue({
          //   matchOfficial: this.matchPins?.matchOfficialPin,
          //   homeTeamOfficial: this.matchPins?.teamOfficial4Pin,
          //   visitingTeamOfficial: this.matchPins?.teamOfficial5Pin,
          //   homeTeamManager: this.matchPins?.teamOneManagerPin,
          //   visitingTeamManager: this.matchPins?.teamTwoManagerPin,
          //   tmo: this.matchPins?.tmoPin,
          //   broadcast: this.matchPins?.broadcastPin
          // })
        }, (error => {
          console.error("Error calling service: " + JSON.stringify(error));
          this.showSnackbar("Unable to get match pin details");
        })
      );
    }

  }

  logoutUser(pin: number) {
    this.matchService.logoutUserByPin(pin).subscribe();
  }

  showSnackbar(message: string): void {
    this._snackBar.open(message, 'OK', {
      horizontalPosition: "center",
      verticalPosition: "top",
      duration: 5000
    });
  }
}
