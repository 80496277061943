<form [formGroup]="form">
  <div class="grid grid-cols-4 grid-rows-8 gap-1">
    <div class="p-1 col-span-4">
      <mat-form-field appearance="outline">
        <mat-label>Report Type</mat-label>
        <mat-select formControlName="reportType">
          <mat-option *ngFor="let reportType of reportTypes" [value]="reportType.value">
            {{ reportType.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="p-1 col-span-1">
      <mat-slide-toggle formControlName="isNonPlayer" style="margin-bottom: 5px">Non Player?</mat-slide-toggle>
      <mat-form-field appearance="outline" class="w-full">
        <mat-label>Team</mat-label>
        <mat-select formControlName="team" (selectionChange)="changePlayerList()">
          <mat-option *ngFor="let team of teams" [value]="team">
            {{ team.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="p-1 col-sp-1">
      <mat-form-field appearance="outline" *ngIf="form.get('isNonPlayer')?.value" class="w-full"
                      style="margin-top: 30px">
        <mat-label>Full Name</mat-label>
        <input matInput formControlName="nonPlayerFullName">
      </mat-form-field>
      <mat-form-field appearance="outline" *ngIf="form.get('team')?.value && !form.get('isNonPlayer')?.value"
                      class="w-full"
                      style="margin-top: 30px">
        <mat-label>Player</mat-label>
        <mat-select formControlName="player">
          <mat-option *ngFor="let player of players" [value]="player">
            {{ player.fullName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="p-1 col-span-1"></div>
    <div class="p-1 col-span-4">
      Match Time of Event, in Minutes, Since the Start of the Match
    </div>
    <div class="p-1 col-span-1">
      <mat-form-field appearance="outline" class="w-full">
        <mat-label>Period of Match</mat-label>
        <mat-select formControlName="periodOfMatch">
          <mat-option *ngFor="let matchPeriodType of matchPeriodTypes" [value]="matchPeriodType.value">
            {{ matchPeriodType.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="p-1 col-span-1">
      <mat-form-field appearance="outline" class="w-full">
        <mat-label>Match Time Minutes</mat-label>
        <input matInput formControlName="elapsedTimeOfPeriodMinutes"
               (keypress)="allowOnlyNumbers($event)">
        <div *ngIf="match.matchType === 'FIFTEEN_A_SIDE'">
          <div
            style="color: red; font-size: 8pt"
            *ngIf="form.controls['elapsedTimeOfPeriodMinutes']?.value != '' && form.controls['periodOfMatch']?.value === 2 && (form.controls['elapsedTimeOfPeriodMinutes']?.value < 40)">
            Time of Event Incorrect (must be more than 40 minutes)
          </div>
          <div
            style="color: red; font-size: 8pt"
            *ngIf="form.controls['elapsedTimeOfPeriodMinutes']?.value != '' && form.controls['periodOfMatch']?.value === 3 && form.controls['elapsedTimeOfPeriodMinutes']?.value < 80">
            Time of Event Incorrect (must be more than 80 minutes)
          </div>
        </div>
        <div *ngIf="match.matchType === 'SEVEN_A_SIDE'">
          <div
            style="color: red; font-size: 8pt"
            *ngIf="form.controls['elapsedTimeOfPeriodMinutes']?.value != '' && form.controls['periodOfMatch']?.value === 2 && (form.controls['elapsedTimeOfPeriodMinutes']?.value < 7)">
            Time of Event Incorrect (must be more than 7 minutes)
          </div>
          <div
            style="color: red; font-size: 8pt"
            *ngIf="form.controls['elapsedTimeOfPeriodMinutes']?.value != '' && form.controls['periodOfMatch']?.value === 3 && form.controls['elapsedTimeOfPeriodMinutes']?.value < 14">
            Time of Event Incorrect (must be more than 14 minutes)
          </div>
        </div>
      </mat-form-field>
    </div>
    <div class="p-1 col-span-1">
      <mat-form-field appearance="outline" class="w-full">
        <mat-label>Match Time Seconds</mat-label>
        <input matInput formControlName="elapsedTimeOfPeriodSeconds"
               (keypress)="allowOnlyNumbers($event)">
      </mat-form-field>
    </div>
    <div class="p-1 col-span-1"></div>
    <div class="p-1 col-span-4">
      Score at the Time of the Event
    </div>
    <div class="p-1 col-span-1">
      <mat-form-field appearance="outline" class="w-full">
        <mat-label>{{ teams[0].name }} Score at Time of Event</mat-label>
        <input matInput formControlName="teamOneScoreAtTimeOfPeriod"
               (keypress)="allowOnlyNumbers($event)">
      </mat-form-field>
    </div>
    <div class="p-1 col-span-1">
      <mat-form-field appearance="outline" class="w-full">
        <mat-label>{{ teams[1].name }} Score at Time of Event</mat-label>
        <input matInput formControlName="teamTwoScoreAtTimeOfPeriod"
               (keypress)="allowOnlyNumbers($event)">
      </mat-form-field>
    </div>
    <div class="p-1 col-span-2"></div>
    <div class="p-1 col-span-4">
      Law Application
    </div>
    <div class="p-1 col-span-2">
      <mat-form-field appearance="outline" class="w-full">
        <mat-label>Applicable Law</mat-label>
        <mat-select formControlName="applicableLaw">
          <mat-option *ngFor="let law of laws" [value]="law" [matTooltip]="law.wording">
            {{ law.lawSection }} - {{ law.description }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="p-1 col-span-2"></div>
    <div class="p-1 col-span-4">
      Approximate Metres
    </div>
    <div class="p-1 col-span-2">
      <mat-form-field appearance="outline" class="w-full">
        <mat-label>Approximate Distance from the Referee to the Event, in Metres</mat-label>
        <input matInput formControlName="proximityMeters"
               (keypress)="allowOnlyNumbers($event)"/>
      </mat-form-field>
    </div>
    <div class="p-1 col-span-1"></div>
    <div class="p-1 col-span-4">
      Detailed Description of the Event
    </div>
    <div class="p-1 col-span-3 row-span-4">
      <mat-form-field appearance="outline" class="w-full h-full" style="height: 250px">
        <mat-label>Report Details</mat-label>
        <input matInput formControlName="reportDetails">
      </mat-form-field>
    </div>
    <div class="p-1 row-span-4"></div>
    <div class="p-1 col-span-4">
      Citing Commissioner’s Details
    </div>
    <div class="p-1 col-span-1">
      <mat-form-field appearance="outline" class="w-full">
        <mat-label>Citing Commissioner Name</mat-label>
        <input matInput formControlName="citingCommissionerName">
      </mat-form-field>
    </div>
    <div class="p-1 col-span-1">
      <mat-form-field appearance="outline" class="w-full">
        <mat-label>Citing Commissioner Email Address</mat-label>
        <input matInput type="email" formControlName="citingCommissionerEmailAddress">
        <mat-error
          *ngIf="form.controls['citingCommissionerEmailAddress']?.invalid && form.controls['citingCommissionerEmailAddress']?.touched && form.controls['citingCommissionerEmailAddress']?.errors?.['email']">
          Invalid email format.
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-span-3"></div>

    <div class="col-span-1 row-span-1 flex justify-between items-center">
      <button mat-raised-button color="accent" style="background-color: green" class="w-1/2"
              (click)="save(false)">Save
      </button>
      <button mat-raised-button color="accent" class="w-1/2" style="margin-left: 10px" (click)="save(true)">Submit
      </button>
    </div>
  </div>
</form>
