import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { GetTeamMappingsResponseDTO } from 'src/app/dtos/GetTeamMappingsResponseDTO';
import { TeamMapping } from 'src/app/interfaces/TeamMapping';
import { MatchService } from 'src/app/services/MatchService';
import { TournamentService } from 'src/app/services/tournament.service';
import { TeamMappingComponent } from '../team-mapping/team-mapping.component';
import {TournamentDetailsComponent} from "../tournament-details/tournament-details.component";

@Component({
  selector: 'app-team-mappings',
  templateUrl: './team-mappings.component.html',
  styleUrls: ['./team-mappings.component.css']
})
export class TeamMappingsComponent {
  filterCriteria?: string;
  datasource: any;
  teamMappings: TeamMapping[] = [];
  displayedColumns: string[] = ['id', 'name', 'abbreviation', 'jerseyColor', 'teamBadge', 'actions'];
  @ViewChild('paginator') paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;

  constructor(
    private matchService: MatchService,
    private router: Router,
    private _snackBar: MatSnackBar,
    private tournamentService: TournamentService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    localStorage.removeItem("matchView");
    this.loadTeamMappings();
  }

  createNewMapping(): void {
    const dialogRef = this.dialog.open(TeamMappingComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.loadTeamMappings();
      localStorage.removeItem('teamMapping');
    });
  }

  doFilter() {
    this.datasource.filter = this.filterCriteria!.trim().toLocaleLowerCase();
  }

  resetFilters() {
    this.filterCriteria = "";
    this.loadTeamMappings();
  }

  loadTeamMappings(): void {
    this.tournamentService.getTeamMappings().subscribe((data: GetTeamMappingsResponseDTO) => {
      this.teamMappings = data.mappings;
      this.datasource = new MatTableDataSource(this.teamMappings);
      this.datasource.sort = this.sort;
      this.datasource.paginator = this.paginator;
    }, (error => {
      console.error("Error calling service: " + JSON.stringify(error));
      this.handleError(error, "Unable to get team mappings");
    }));
  }

  deleteTeamMapping(id: number): void {
    if (confirm("Are you sure to delete the mapping?")) {
      this.tournamentService.deleteTeamMapping(id).subscribe(() => {
        this.loadTeamMappings();
      }, (error => {
        console.error("Error calling service: " + JSON.stringify(error));
        this.handleError(error, "Unable to delete team mappings");
      }));
    }
  }

  getColor(id: number): string {
    const found = this.teamMappings.find((obj) => {
      return obj.id == id;
    });
    return found!.jerseyColor;
  }

  getSanitizedImageWithName(imageName: String | undefined) {
    return "assets/images/flags/" + imageName
  }

  editTeamMapping(id: number): void {
    const found = this.teamMappings.find((obj) => {
      return obj.id == id;
    })
    localStorage.setItem('teamMapping', JSON.stringify(found));
    const dialogRef = this.dialog.open(TeamMappingComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.loadTeamMappings();
      localStorage.removeItem('teamMapping');
    });
  }

  handleError(error: any, errorMessage: string): void {
    this._snackBar.open(errorMessage, 'OK', {
      horizontalPosition: "center",
      verticalPosition: "top",
    });
  }
}
