import {Component, ViewChild} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {ActivatedRoute, Router} from '@angular/router';
import {UserAssignedTournamentService} from "../../services/UserAssignedTournamentService";
import {UserAssignedTournamentsResponseDTO} from "../../dtos/UserAssignedTournamentsResponseDTO";
import {UserAssignedTournament} from "../../interfaces/UserAssignedTournament";
import {
  UserAssignedTournamentDetailsComponent
} from "../user-assigned-tournament-details/user-assigned-tournament-details.component";

@Component({
  selector: 'app-user-assigned-tournaments',
  templateUrl: './user-assigned-tournaments.component.html',
  styleUrls: ['./user-assigned-tournaments.component.css']
})
export class UserAssignedTournamentsComponent {
  filterCriteria?: string;
  datasource: any;
  portalUserId!: number;
  userAssignedTournaments: UserAssignedTournament[] = [];
  displayedColumns: string[] = ['email', 'tournament', 'actions'];
  @ViewChild('paginator') paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });

  constructor(
    private userAssignedTournamentService: UserAssignedTournamentService,
    private router: Router,
    private route: ActivatedRoute,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog
  ) {
  }

  ngAfterViewInit(): void {

  }

  ngOnInit(): void {
    const routeParams = this.route.snapshot.paramMap;

    this.portalUserId = Number(routeParams.get("id")!);

    this.loadUserAssignedTournaments();
  }

  doFilter() {
    this.datasource.filter = this.filterCriteria!.trim().toLocaleLowerCase();
  }

  resetFilters() {
    this.range.reset();
    this.filterCriteria = "";
    this.loadUserAssignedTournaments();
  }

  loadUserAssignedTournaments(): void {
    this.userAssignedTournamentService.getAllUserAssignedTournaments(this.portalUserId).subscribe((data: UserAssignedTournamentsResponseDTO) => {
      this.userAssignedTournaments = data.userAssignedTournaments;
      this.datasource = new MatTableDataSource(this.userAssignedTournaments);
      this.datasource.sort = this.sort;
      this.datasource.paginator = this.paginator;
    }, (error => {
      console.error("Error calling service: " + JSON.stringify(error));
      this.handleError(error, "Unable to get user assigned tournaments");
    }));
  }

  createUserAssignedTournament(): void {
    localStorage.setItem('portalUserIdToAssign', String(this.portalUserId));
    const dialogRef = this.dialog.open(UserAssignedTournamentDetailsComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.loadUserAssignedTournaments();
      localStorage.removeItem('portalUserIdToAssign');
    });
  }

  delete(id: number): void {
    if (confirm("Are you sure you would like to delete this tournament assignment?")) {
      this.userAssignedTournamentService.deleteUserAssignedTournament(id)
        .subscribe(() => {
          this.loadUserAssignedTournaments();
        }, error => {
          console.error("Error calling service: " + JSON.stringify(error));
          this.handleError(error, "Unable to delete user assigned tournament");
        });
    }
  }

  handleError(error: any, errorMessage: string): void {
    this._snackBar.open(errorMessage, 'OK', {
      horizontalPosition: "center",
      verticalPosition: "top",
    });
  }
}
