import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SanctionDTO } from '../dtos/SanctionDTO';

@Injectable({
  providedIn: 'root'
})
export class SanctionsService {

  url = environment.apiUrl + "sanctions/";
  
  constructor(
    private http: HttpClient
  ) { }

  getMatchSanctions(matchId: number) {
    return this.http.get<SanctionDTO[]>(this.url + "byMatchId/" + matchId);
  }

  getSanctionReport(sanctionId: number) {
    return this.http.get<Blob>(this.url + sanctionId + "/download", { observe: 'response', responseType: 'blob' as 'json'});
  }
}
