<div class="bg-image w-full h-full">
    <div class="flex items-center justify-center h-screen">
        <div class="bg-white text-blue-800 font-bold rounded-lg border shadow-lg p-10 w-80 space-y-4">
            <div class="flex justify-center"><img src="assets/images/logos/World_Rugby_logo.png" width="50" height="50">
            </div>
            <div class="text-center">Welcome to the Rugby Player Movement Administration Portal.</div>

            <div class="text-center mat-caption">Please login to continue....</div>
            <div class="flex justify-center"><button mat-raised-button color="primary"
                    (click)="login()">Login</button></div>
        </div>
    </div>
</div>
