import {CanActivate, Router} from "@angular/router";
import {MsalService} from "@azure/msal-angular";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private authService: MsalService, private router: Router) {}

  canActivate(): boolean {
    const isAuthenticated = this.authService.instance.getActiveAccount() != null;

    if (!isAuthenticated) {
      // User is not logged in, redirect to login page
      this.router.navigate(['/login']);
      return false;
    } else {
      return true;
    }
  }
}
