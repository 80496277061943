<mat-card>
  <mat-card-header>
    <div class="flex flex-row w-full">
      <div class="flex flex-col w-full">
        <div class="flex flex-row justify-center items-center gap-4">
          <div class="font-semibold text-lg">Portal User Details</div>
        </div>
      </div>
    </div>
  </mat-card-header>
  <mat-card-content>
    <div class="grid grid-cols-2 gap-3 p-10">
      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input matInput placeholder="Email" [(ngModel)]="email" required>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Role Type</mat-label>
        <mat-select [(ngModel)]="portalUserRoleType" required>
          <mat-option *ngFor="let portalUserRoleType of portalUserRoleTypes" [value]="portalUserRoleType.value">
            {{ portalUserRoleType.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <button mat-raised-button (click)="save()" color="accent" class="float-right btn ">Save</button>
      <button mat-raised-button (click)="cancel()" color="warn" class="float-right btn ">Cancel</button>
    </div>
  </mat-card-content>
</mat-card>
