import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { MatchConfigurationDTO } from 'src/app/dtos/MatchConfigurationDTO';
import { MatchDTO } from 'src/app/dtos/MatchDTO';
import { PlayerDTO } from 'src/app/dtos/PlayerDTO';
import { UpdateOfficialsRequestDTO } from 'src/app/dtos/UpdateOfficialsRequestDTO';
import { UpdateTeamPlayersRequestDTO } from 'src/app/dtos/UpdateTeamPlayersRequestDTO';
import { Match } from 'src/app/interfaces/Match';
import { MatchSettings } from 'src/app/interfaces/MatchSettings';
import { Officials } from 'src/app/interfaces/Officials';
import { MatchService } from 'src/app/services/MatchService';

@Component({
  selector: 'app-view-match',
  templateUrl: './view-match.component.html',
  styleUrls: ['./view-match.component.css']
})
export class ViewMatchComponent implements OnInit {

  firstFormGroup!: FormGroup;
  homeTeamName?: String = "";
  visitingTeamName?: String = "";
  tournament?: String = "";
  matchDate?: String | null;
  venue?: string | null;
  match?: MatchDTO;
  homeTeamPlayers!: FormArray;
  awayTeamPlayers!: FormArray;

  matchDetails?: Match;
  officials?: Officials;
  matchSettings?: MatchSettings;
  onFieldPlayers: number = 15;
  homeTeam?: PlayerDTO[] = [];
  visitingTeam?: PlayerDTO[] = [];
  homeTeamBadge?: string;
  visitingTeamBadge?: string;

  constructor(
    private route: ActivatedRoute,
    private matchService: MatchService,
    public datepipe: DatePipe,
    private _snackBar: MatSnackBar,
    private _sanitizer: DomSanitizer,
    private _formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.firstFormGroup = this._formBuilder.group({
    });
    this.homeTeamPlayers = this._formBuilder.array([]);
    this.awayTeamPlayers = this._formBuilder.array([]);
    const routeParams = this.route.snapshot.paramMap;
    if (routeParams.get("id") != null) {
      console.log("restoring form values...");
      this.match = JSON.parse(localStorage.getItem("matchView")!);
      this.visitingTeamBadge = this.match?.teamTwo.teamBadge;
      this.homeTeamBadge = this.match?.teamOne.teamBadge;
      this.homeTeamName = this.match?.teamOne.name;
      this.visitingTeamName = this.match?.teamTwo.name;
      this.tournament = this.match?.tournament.title;
      this.matchDate = this.datepipe.transform(this.match?.matchDate, 'yyyy-MM-dd HH:mm');
      this.venue = this.match?.stadium;
      this.mapTeams();
    }
  }

  getSanitizedImageWithName(imageName: String | undefined) {
    return "assets/images/flags/" + imageName
  }

  onSubmit(): void {
    console.log(this.firstFormGroup);
    console.log(this.firstFormGroup.get("matchDetails")?.value)
    console.log(this.firstFormGroup.get("officials")?.value)
    console.log(this.firstFormGroup.get("matchSettings")?.value)

    this.homeTeamPlayers.controls.forEach((element, index) => {
      console.log(element.get("playerName")?.value)
    });

    this.saveMatchDetails();
    this.saveMatchOfficials();
    this.saveHomeTeam();
    this.saveVisitingTeam();
  }

  saveMatchDetails(): void {
    console.log("save match clicked");
    console.log(this.firstFormGroup?.value);
    this.matchDetails = this.firstFormGroup?.get("matchDetails")?.value;
    console.log(this.datepipe.transform(this.matchDetails?.matchDate, 'yyyy-MM-dd'));
    console.log(this.matchDetails?.matchTime);
    let dateString = this.datepipe.transform(this.matchDetails?.matchDate, 'yyyy-MM-dd') + 'T' + this.matchDetails?.matchTime;
    console.log(dateString);
    let date = new Date(dateString);
    console.log(date);
    let m: MatchDTO = JSON.parse(localStorage.getItem("matchView")!);
    if (this.matchDetails != null) {
      this.matchDetails!.matchDate = date;
      this.matchService.updateMatch(this.matchDetails, m.id!, m.teamOne.id!, m.teamTwo.id!).subscribe((data: MatchDTO) => {
        console.log("match details updated successfully");
        this.saveMatchSettings();
        this.showSnackbar("Match details updated successfully");
      },
        (error) => {
          console.error(JSON.stringify(error));
        });
    }
  }

  saveMatchOfficials(): void {
    console.log("save officials clicked");
    this.officials = this.firstFormGroup?.get("officials")?.value;
    let m: MatchDTO = JSON.parse(localStorage.getItem("matchView")!);
    if (m != null && this.officials != null) {
      let officials: UpdateOfficialsRequestDTO = {
        officials: [{
          id: m.referee.id,
          fullName: this.officials.referee,
          role: m.referee.role
        },
        {
          id: m.assistantRefereeOne.id,
          fullName: this.officials.assistantReferee1,
          role: m.assistantRefereeOne.role
        },
        {
          id: m.assistantRefereeTwo.id,
          fullName: this.officials.assistantReferee2,
          role: m.assistantRefereeTwo.role
        },
        {
          id: m.matchOfficialOne.id,
          fullName: this.officials.matchOfficial4,
          role: m.matchOfficialOne.role
        },
        {
          id: m.matchOfficialTwo.id,
          fullName: this.officials.matchOfficial5,
          role: m.matchOfficialTwo.role
        },
        {
          id: m.matchOfficialThree.id,
          fullName: this.officials.matchOfficial6,
          role: m.matchOfficialThree.role
        },
        {
          id: m.tmo.id,
          fullName: this.officials.tmo,
          role: m.tmo.role
        }]
      };
      this.matchService.updateMatchOfficials(officials, m.id!).subscribe((data: UpdateOfficialsRequestDTO) => {
        console.log("match details updated successfully");
        this.showSnackbar("Match details updated successfully");
      },
        (error) => {
          console.error(JSON.stringify(error));
        });
    }
  }

  saveHomeTeam(): void {
    console.log("save home team clicked");
    let m: MatchDTO = JSON.parse(localStorage.getItem("matchView")!);
    if (m.matchType == "SEVEN_A_SIDE") {
      this.onFieldPlayers = 7;
    }
    this.homeTeamPlayers?.controls.forEach((element, index) => {
      let player: PlayerDTO = {
        caption: false,
        fullName: element.get("playerName")?.value,
        hooker: element.get("hooker")?.value,
        jerseyNumber: element.get("jerseyNumber")?.value,
        looseHead: element.get("looseHead")?.value,
        onField: element.get("position")?.value <= this.onFieldPlayers ? true : false,
        position: element.get("position")?.value,
        frontRowPosition: element.get("position")?.value <= 3 ? element.get("position")?.value : null,
        prop: element.get("looseHead")?.value == true || element.get("hooker")?.value == true || element.get("tightHead")?.value == true,
        startingFifteen: element.get("position")?.value <= this.onFieldPlayers ? true : false,
        tightHead: element.get("tightHead")?.value,
        u19: element.get("u19")?.value,
        id: element.get("id")?.value
      }
      this.homeTeam?.push(player);
    })
    console.log(this.homeTeam)
    if (m != null && this.homeTeam != null) {
      let players: UpdateTeamPlayersRequestDTO = {
        players: this.homeTeam
      }
      this.matchService.updateTeamPlayers(players, m.teamOne.id!).subscribe((data: UpdateTeamPlayersRequestDTO) => {
        console.log("match details updated successfully");
        this.showSnackbar("Match details updated successfully");
      },
        (error) => {
          console.error(JSON.stringify(error));
        });
    }
  }

  saveVisitingTeam(): void {
    console.log("save visiting team clicked");
    let m: MatchDTO = JSON.parse(localStorage.getItem("matchView")!);
    if (m.matchType == "SEVEN_A_SIDE") {
      this.onFieldPlayers = 7;
    }
    this.awayTeamPlayers?.controls.forEach((element, index) => {
      let player: PlayerDTO = {
        caption: false,
        fullName: element.get("playerName")?.value,
        hooker: element.get("hooker")?.value,
        jerseyNumber: element.get("jerseyNumber")?.value,
        looseHead: element.get("looseHead")?.value,
        onField: element.get("position")?.value <= this.onFieldPlayers ? true : false,
        position: element.get("position")?.value,
        frontRowPosition: element.get("position")?.value <= 3 ? element.get("position")?.value : null,
        prop: element.get("looseHead")?.value == true || element.get("hooker")?.value == true || element.get("tightHead")?.value == true,
        startingFifteen: element.get("position")?.value <= this.onFieldPlayers ? true : false,
        tightHead: element.get("tightHead")?.value,
        u19: element.get("u19")?.value,
        id: element.get("id")?.value
      }
      this.visitingTeam?.push(player);
    })
    console.log(this.homeTeam)
    if (m != null && this.visitingTeam != null) {
      let players: UpdateTeamPlayersRequestDTO = {
        players: this.visitingTeam
      }
      this.matchService.updateTeamPlayers(players, m.teamTwo.id!).subscribe((data: UpdateTeamPlayersRequestDTO) => {
        console.log("match details updated successfully");
        this.showSnackbar("Match details updated successfully");
      },
        (error) => {
          console.error(JSON.stringify(error));
        });
    }
  }

  saveMatchSettings(): void {
    console.log("save match settings clicked");
    this.matchSettings = this.firstFormGroup?.get("matchSettings")?.value;
    let m: MatchDTO = JSON.parse(localStorage.getItem("matchView")!);
    if (m != null) {
      let settings: MatchConfigurationDTO = {
        id: m.matchConfiguration.id,
        yellowCardLength: this.matchSettings?.yellowCardTime!,
        hiaLength: this.matchSettings?.hiaTime!,
        halfTimeLength: this.matchSettings?.halfTimeLength!,
        extraTimeAllowed: this.matchSettings?.extraTimeAllowed!,
        halfLength: this.matchSettings?.timePerHalf!,
        extraTimeHalfLength: this.matchSettings?.extraTimePerHalf!,
        bloodBinLength: this.matchSettings?.bloodBinTime!,
        numberOfSubstitutions: this.firstFormGroup?.get("matchDetails")?.get("numberOfSubs")?.value,
        redCardLength: this.matchSettings?.redCardLength!
      }
      this.matchService.updateMatchSettings(settings, m.id!).subscribe((data: MatchConfigurationDTO) => {
        console.log("match details updated successfully");
        this.showSnackbar("Match details updated successfully");
      },
        (error) => {
          console.error(JSON.stringify(error));
        });
    }
  }

  showSnackbar(message: string): void {
    this._snackBar.open(message, 'OK', {
      horizontalPosition: "center",
      verticalPosition: "top",
      duration: 5000
    });
  }

  mapTeams() {
    console.log("mapping team players");
    this.match = JSON.parse(localStorage.getItem("matchView")!);
    this.match?.teamOne.players?.sort((a, b) => (a.position < b.position ? -1 : 1));
    this.match?.teamOne.players?.forEach(value => {
      console.log(value.position)
      this.homeTeamPlayers?.push(this._formBuilder.group({
        playerName: [value.fullName],
        looseHead: [value.looseHead],
        hooker: [value.hooker],
        tightHead: [value.tightHead],
        u19: [value.u19],
        position: [value.position],
        id: [value.id],
        jerseyNumber: [value.jerseyNumber]
      }));
    });

    this.match?.teamTwo.players?.sort((a, b) => (a.position < b.position ? -1 : 1));
    this.match?.teamTwo.players?.forEach(value => {
      this.awayTeamPlayers?.push(this._formBuilder.group({
        playerName: [value.fullName],
        looseHead: [value.looseHead],
        hooker: [value.hooker],
        tightHead: [value.tightHead],
        u19: [value.u19],
        position: [value.position],
        id: [value.id],
        jerseyNumber: [value.jerseyNumber]
      }));
    });
  }

}
