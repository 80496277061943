<div class="flex-col pt-5 h-full">
  <table mat-table [dataSource]="datasource" matSort #table>
    <ng-container matColumnDef="label">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> LABEL.</th>
      <td mat-cell *matCellDef="let element"> {{ element.label }}</td>
    </ng-container>
    <ng-container matColumnDef="role">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> ROLE</th>
      <td mat-cell *matCellDef="let element"> {{ element.role }}</td>
    </ng-container>
    <ng-container matColumnDef="code">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> CODE</th>
      <td mat-cell *matCellDef="let element"> {{ element.code }}</td>
    </ng-container>
    <ng-container matColumnDef="loggedIn">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> LOGGED IN</th>
      <td mat-cell *matCellDef="let element"> {{ element.loggedIn === true ? 'True' : 'False' }}
        <button mat-raised-button color="primary" class="btn-3" (click)="logoutUser(element.code)"
                matTooltip="Logout User" [hidden]="element.loggedIn != true">
          <mat-icon class="btn-mat-icon-darkblue">logout</mat-icon>
        </button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
<div class="flex-col pt-5 h-full">
  <table mat-table [dataSource]="datasourceTournamentLoginCodes" matSort #table>
    <ng-container matColumnDef="label">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> TOURNAMENT USER</th>
      <td mat-cell *matCellDef="let element"> {{ element.label }}</td>
    </ng-container>
    <ng-container matColumnDef="role">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> ROLE</th>
      <td mat-cell *matCellDef="let element"> {{ element.role }}</td>
    </ng-container>
    <ng-container matColumnDef="code">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> TOURNAMENT CODE</th>
      <td mat-cell *matCellDef="let element"> {{ element.code }}</td>
    </ng-container>
    <ng-container matColumnDef="loggedIn">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> LOGGED IN</th>
      <td mat-cell *matCellDef="let element"> {{ element.loggedIn === true ? 'True' : 'False' }}
        <button mat-raised-button color="primary" class="btn-3" (click)="logoutUser(element.code)"
                matTooltip="Logout User" [hidden]="element.loggedIn != true">
          <mat-icon class="btn-mat-icon-darkblue">logout</mat-icon>
        </button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
