<mat-toolbar>
  <mat-toolbar-row [ngClass]="{'light-toolbar': environment.production, 'light-toolbar-dev': !environment.production}">
    <div class="flex flex-row w-full items-center">
      <div class="basis-1/4">
        <div class="flex flex-row w-full items-center gap-2">
          <img class="toolbar-img pl-5" src="assets/images/logo-login.svg">
          <span class="font-medium text-xl float-left">|</span>
          <span class="font-medium text-xl float-left mt-1">Switch - {{ portalUserService.getUserRole() === 'ADMIN' ? 'World Rugby Admin ' : (portalUserService.getUserRole() === 'USER' ? 'Competition Admin' : (portalUserService.getUserRole() === 'CITING' ?'Citing' : '')) }}</span>
        </div>
      </div>
      <div class="basis-1/2"></div>
      <div class="basis-1/4">
        <button mat-raised-button color="accent" class="float-right btn" (click)="logout()">Logout</button>
      </div>
    </div>
  </mat-toolbar-row>

  <mat-toolbar-row class="dark-toolbar">
    <div class="flex flex-row space-x-2.5">
      <button mat-button class="menu-btn" [routerLink]="['/matches']" routerLinkActive="active">MATCHES</button>
      <button mat-button class="menu-btn" [routerLink]="['/tournaments']" routerLinkActive="active" *ngIf="portalUserService.getUserRole() !== 'CITING'">TOURNAMENTS</button>
      <button mat-button class="menu-btn" [routerLink]="['/team-mappings']" routerLinkActive="active" *ngIf="portalUserService.getUserRole() === 'ADMIN'">TEAM MAPPINGS</button>
      <button mat-button class="menu-btn" [routerLink]="['/portal-users']" routerLinkActive="active" *ngIf="portalUserService.getUserRole() === 'ADMIN'">PORTAL USERS</button>

    </div>
  </mat-toolbar-row>
</mat-toolbar>

<div class="p-8">
  <router-outlet></router-outlet>
  <app-progress-spinner></app-progress-spinner>
</div>
