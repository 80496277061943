import { Component, Input } from '@angular/core';
import { ControlContainer, FormArray, FormBuilder, FormGroup, FormGroupDirective } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MatchDTO } from 'src/app/dtos/MatchDTO';
import { PlayerDTO } from 'src/app/dtos/PlayerDTO';

@Component({
  selector: 'app-team-players',
  templateUrl: './team-players.component.html',
  styleUrls: ['./team-players.component.css'],
  viewProviders: [
    {provide: ControlContainer, useExisting: FormGroupDirective}
  ]
})
export class TeamPlayersComponent {
  form?: FormGroup;
  teamSubForm?: FormGroup;
  match?: MatchDTO;

  @Input() isHomeTeam: boolean = false;
  @Input() readOnly: boolean = false;
  @Input() players!: FormArray;

  constructor(private fb: FormBuilder,
    private route: ActivatedRoute,
    private ctrlContainer: FormGroupDirective) { }

  get playerControls() {
    if (this.players != null) {
      return this.players.controls as FormGroup[];
    } else {
      return null
    }
  }

  ngOnInit(): void {
    this.form = this.ctrlContainer.form;
    this.teamSubForm = this.fb.group({});

    const routeParams = this.route.snapshot.paramMap;
    console.log("MATCH COMPONENT " + routeParams.get("matchId"));
    if (routeParams.get("id") != null) {
      this.mapDataToForm()
    }
  }

  mapDataToForm() {
    console.log("restoring form values...");
    this.match = JSON.parse(localStorage.getItem("matchView")!);
    let team = this.isHomeTeam === true ? this.match?.teamOne : this.match?.teamTwo
    team?.players?.forEach(value => {
      this.teamSubForm?.patchValue({
        ["player" + value.position]: value.fullName,
        ["player" + value.position + "TH"]: value.tightHead,
        ["player" + value.position + "LH"]: value.looseHead,
        ["player" + value.position + "HC"]: value.hooker,
        ["player" + value.position + "u19"]: value.u19
      });
    });
  }

  onSave(): void {
    console.log("####Saving team");
    console.log(this.players);
  }


}
