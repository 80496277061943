import {Component, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatTableDataSource} from '@angular/material/table';
import {ActivatedRoute} from '@angular/router';
import {GetMatchPinsResponseDto} from 'src/app/dtos/GetMatchPinsResponseDto';
import {MatchPin} from 'src/app/interfaces/MatchPin';
import {MatchService} from 'src/app/services/MatchService';
import {TournamentService} from "../../services/tournament.service";
import {GetAppTournamentLoginsResponseDTO} from "../../dtos/GetAppTournamentLoginsResponseDTO";

@Component({
  selector: 'app-match-pins',
  templateUrl: './match-pins.component.html',
  styleUrls: ['./match-pins.component.css']
})
export class MatchPinsComponent implements OnInit {
  filterCriteria?: string;
  datasource: any;
  datasourceTournamentLoginCodes: any;
  displayedColumns: string[] = ['label', 'role', 'code', 'loggedIn'];
  matchPins?: GetMatchPinsResponseDto;
  matchId?: number;
  matchPinList: MatchPin[] = []
  matchPinTournamentList: MatchPin[] = []

  constructor(private fb: FormBuilder,
              private route: ActivatedRoute,
              private matchService: MatchService,
              private tournamentService: TournamentService,
              private _snackBar: MatSnackBar) {

  }

  ngOnInit(): void {
    const routeParams = this.route.snapshot.paramMap;
    if (routeParams.get("id") != null) {
      this.matchId = Number(routeParams.get("id"));

      this.matchService.getMatchPins(this.matchId)
        .subscribe((data: GetMatchPinsResponseDto) => {
            console.log(data);
            this.matchPins = data;
            var roNum = 0;
            for (let pin in this.matchPins.accessDetails) {

              const m = this.matchPins.accessDetails[pin];
              const c = m.code;
              var r = m.role;
              var l = "";
              if (r == "TEAM_A_MANAGER") {
                l = "Team A Team Manager";
                r = "Team A Manager";
              } else if (r == "TEAM_B_MANAGER") {
                l = "Team B Team Manager";
                r = "Team B Manager";
              } else if (r == "SIDE_LINE_MANAGER") {
                l = "Side Line Manager";
                r = "Side Line Manager";
              } else if (r == "REPORTER") {
                l = "Technical Zone Manager";
                r = "Reporter";
              } else if (r == "ADDITIONAL_REPORTER") {
                l = "Additional Reporter";
                r = "Additional Reporter";
              } else if (r == "REFEREE_REPORTER") {
                l = "Referee Reporter";
                r = "Referee Reporter";
              } else if (r == "TMO") {
                l = "TMO";
                r = "TMO";
              } else if (r == "BROADCAST") {
                l = "Broadcast";
                r = "Read Only";
              } else if (r == "READ_ONLY") {
                roNum++;
                if (roNum == 1) {
                  l = "Sports Presentation";
                  r = "Read Only";
                } else if (roNum == 2) {
                  l = "Stats Provider 01";
                  r = "Read Only";
                } else if (roNum == 3) {
                  l = "Stats Provider 02";
                  r = "Read Only";
                } else if (roNum == 4) {
                  l = "MTO";
                  r = "Read Only";
                } else {
                  l = "Read Only " + roNum;
                  r = "Read Only";
                }
              }
              this.matchPinList!.push({
                code: c,
                label: l,
                role: r,
                loggedIn: m.loggedIn
              })

              this.datasource = new MatTableDataSource(this.matchPinList)
            }

            this.tournamentService.getAppTournamentLoginPins(this.matchId!)
              .subscribe((data: GetAppTournamentLoginsResponseDTO) => {
                console.log("OH MY GOODNESS!!!")
                console.log(data.appTournamentLogins.length)
                for (let appTournamentLogin in data.appTournamentLogins) {

                  const tournamentLogin = data.appTournamentLogins[appTournamentLogin];

                  this.matchPinTournamentList!.push({
                    code: tournamentLogin.securityCode,
                    label: tournamentLogin.label,
                    role: tournamentLogin.appRoleType,
                    loggedIn: tournamentLogin.loggedIn
                  })

                  this.datasourceTournamentLoginCodes = new MatTableDataSource(this.matchPinTournamentList)
                }
              }, (error => {
                console.error("Error calling service: " + JSON.stringify(error));
                this.showSnackbar("Unable to get match pin details");
              }));
          }, (error => {
            console.error("Error calling service: " + JSON.stringify(error));
            this.showSnackbar("Unable to get match pin details");
          })
        );
    }
  }

  logoutUser(pin: number) {
    this.matchService.logoutUserByPin(pin).subscribe();
  }

  showSnackbar(message: string): void {
    this._snackBar.open(message, 'OK', {
      horizontalPosition: "center",
      verticalPosition: "top",
      duration: 5000
    });
  }
}
