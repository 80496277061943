<mat-card class="drop-shadow-lg">
  <mat-card-header>
    <div class="flex flex-row w-full">
      <div class="flex flex-col w-full">
        <div class="flex flex-row justify-center items-center gap-4">
          <div class="font-semibold text-lg">
            <div class="flex items-center justify-center gap-2"><img class="teamBadge"
                                                                     *ngIf="homeTeamBadge != null"
                                                                     [src]='getSanitizedImageWithName(homeTeamBadge)'>{{ homeTeamName }}
              vs
              {{ visitingTeamName }} <img class="teamBadge" *ngIf="visitingTeamBadge != null"
                                          [src]='getSanitizedImageWithName(visitingTeamBadge)'></div>
          </div>
          <!-- <div class="font-normal content-center text-base">|</div> -->

        </div>
        <div class="font-normal text-center text-base">{{ tournament }}</div>
        <div class="font-light text-center text-sm">{{ venue }} | {{ matchDate }}</div>
      </div>
      <button mat-raised-button color="primary" class="float-right btn" (click)="onSubmit()"
              *ngIf="portalUserService.getUserRole() !== 'CITING'">Update
      </button>
    </div>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="firstFormGroup">
      <mat-tab-group class="pt-5" preserveContent [(selectedIndex)]="selectedTabIndex">
        <mat-tab label="Match Details">
          <div class="pt-5 pb-5">
            <app-match-details></app-match-details>
          </div>
        </mat-tab>
        <mat-tab label="Officials">
          <div class="pt-5 pb-5">
            <app-officials-details></app-officials-details>
          </div>
        </mat-tab>
        <mat-tab label="{{homeTeamName}}">
          <div class="pt-5 pb-5">
            <app-team-players [isHomeTeam]="true" [readOnly]="false"
                              [players]="homeTeamPlayers"></app-team-players>
          </div>
        </mat-tab>
        <mat-tab label="{{visitingTeamName}}">
          <div class="pt-5 pb-5">
            <app-team-players [isHomeTeam]="false" [readOnly]="false"
                              [players]="awayTeamPlayers"></app-team-players>
          </div>
        </mat-tab>
        <mat-tab label="Match Settings">
          <div class="pt-5 pb-5">
            <app-match-settings></app-match-settings>
          </div>
        </mat-tab>
        <mat-tab label="Access Codes" [disabled]="portalUserService.getUserRole() === 'CITING'">
          <div class="pt-5 pb-5">
            <app-match-pins></app-match-pins>
          </div>
        </mat-tab>
        <mat-tab label="Reports">
          <div class="pt-5 pb-5 pl-5 pr-5">
            <app-reports></app-reports>
          </div>
        </mat-tab>
        <!-- <mat-tab label="Timeline">
            <div class="pt-5 pb-5">
                <app-match-timeline></app-match-timeline>
            </div>
        </mat-tab> -->
      </mat-tab-group>
    </form>
  </mat-card-content>
</mat-card>
