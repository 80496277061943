import {Component, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {ActivatedRoute, Router} from '@angular/router';
import {MatchService} from 'src/app/services/MatchService';
import {TournamentService} from 'src/app/services/tournament.service';
import {GetTournamentTeamMappingsResponseDTO} from "../../dtos/GetTournamentTeamMappingsResponseDTO";
import {TournamentTeamMapping} from "../../interfaces/TournamentTeamMapping";
import {TeamMappingComponent} from "../team-mapping/team-mapping.component";
import {TournamentTeamMappingComponent} from "../tournament-team-mapping/tournament-team-mapping.component";

@Component({
  selector: 'app-tournament-team-mappings',
  templateUrl: './tournament-team-mappings.component.html',
  styleUrls: ['./tournament-team-mappings.component.css']
})
export class TournamentTeamMappingsComponent {
  filterCriteria?: string;
  datasource: any;
  tournamentTeamMappings: TournamentTeamMapping[] = [];
  displayedColumns: string[] = ['id', 'tournamentName', 'teamName', 'teamEmails', 'actions'];
  @ViewChild('paginator') paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;

  constructor(
    private matchService: MatchService,
    private router: Router,
    private route: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private tournamentService: TournamentService,
    public dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    console.log("I am in TournamentTeamMappingsComponent");
    //localStorage.removeItem("matchView");
    this.loadTournamentTeamMappings();
  }

  // createNewMapping(): void {
  //   const dialogRef = this.dialog.open(TournamentTeamMappingComponent);
  //
  //   dialogRef.afterClosed().subscribe(result => {
  //     console.log('The dialog was closed');
  //     this.loadTeamMappings();
  //     localStorage.removeItem('teamMapping');
  //   });
  // }

  doFilter() {
    this.datasource.filter = this.filterCriteria!.trim().toLocaleLowerCase();
  }

  resetFilters() {
    this.filterCriteria = "";
    this.loadTournamentTeamMappings();
  }

  loadTournamentTeamMappings(): void {
    const routeParams = this.route.snapshot.paramMap;
    let tournamentId = Number(routeParams.get("id"));
    this.tournamentService.getTournamentTeamMappings(tournamentId).subscribe((data: GetTournamentTeamMappingsResponseDTO) => {
      this.tournamentTeamMappings = data.tournamentTeamMappings;
      this.datasource = new MatTableDataSource(this.tournamentTeamMappings);
      this.datasource.sort = this.sort;
      this.datasource.paginator = this.paginator;
    }, (error => {
      console.error("Error calling service: " + JSON.stringify(error));
      this.handleError(error, "Unable to get Tournament Team Mappings");
    }));
  }

  editTournamentTeamMapping(id: number): void {
    const found = this.tournamentTeamMappings.find((obj) => {
      return obj.id == id;
    })
    localStorage.setItem('tournamentTeamMapping', JSON.stringify(found));
    const dialogRef = this.dialog.open(TournamentTeamMappingComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.loadTournamentTeamMappings();
      localStorage.removeItem('tournamentTeamMapping');
    });
  }

  // getColor(id: number): string {
  //   const found = this.teamMappings.find((obj) => {
  //     return obj.id == id;
  //   });
  //   return found!.jerseyColor;
  // }

  // getSanitizedImageWithName(imageName: String | undefined) {
  //   return "assets/images/flags/" + imageName
  // }

  // editTeamMapping(id: number): void {
  //   const found = this.teamMappings.find((obj) => {
  //     return obj.id == id;
  //   })
  //   localStorage.setItem('teamMapping', JSON.stringify(found));
  //   const dialogRef = this.dialog.open(TournamentTeamMappingComponent);
  //
  //   dialogRef.afterClosed().subscribe(result => {
  //     console.log('The dialog was closed');
  //     this.loadTeamMappings();
  //     localStorage.removeItem('teamMapping');
  //   });
  // }

  handleError(error: any, errorMessage: string): void {
    this._snackBar.open(errorMessage, 'OK', {
      horizontalPosition: "center",
      verticalPosition: "top",
    });
  }
}
