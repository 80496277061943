<div class="flex flex-row w-full items-center">
  <div class="basis-1/4">
    <mat-form-field appearance="outline" class="w-full">
      <mat-label>Search Team</mat-label>
      <input type="search" matInput placeholder="Search Team" [(ngModel)]="filterCriteria" (keyup)="doFilter()">
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
  </div>
  <div class="basis-1/4 pl-3">
    <mat-form-field appearance="outline" class="w-full">
      <mat-label>Enter a date range</mat-label>
      <mat-date-range-input [rangePicker]="picker" [formGroup]="range">
        <input matStartDate formControlName="start" placeholder="Start date">
        <input matEndDate formControlName="end" placeholder="End date">
      </mat-date-range-input>
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker>
        <mat-date-range-picker-actions>
          <button mat-button matDateRangePickerCancel>Cancel</button>
          <button mat-raised-button color="primary" (click)="doDateFilter()"
                  matDateRangePickerApply>Apply
          </button>
        </mat-date-range-picker-actions>
      </mat-date-range-picker>
    </mat-form-field>
  </div>
  <div class="basis-1/4 pl-3">
    <mat-form-field appearance="outline" class="w-full">
      <mat-label>Tournament</mat-label>
      <mat-select [(value)]="tournamentId" (selectionChange)="doFilterByTournament()">
        <mat-option *ngFor="let tournament of tournaments" [value]="tournament.id">
          {{ tournament.title }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="basis-1/2">
    <button mat-raised-button color="warn" class="btn-4 ml-3 float-left mb-6" (click)="resetFilters()"
            matTooltip="Reset filters">
      <mat-icon class="btn-mat-icon-white">clear</mat-icon>
    </button>
    <button mat-raised-button color="accent" class="btn-4 ml-3 float-left mb-6" (click)="loadMatches()"
            matTooltip="Reload matches">
      <mat-icon class="btn-mat-icon-white">refresh</mat-icon>
    </button>

    <button mat-raised-button [routerLink]="['/new-match']" color="accent" class="float-right btn "
            matTooltip="Create a new match">New
      Match
    </button>
  </div>
</div>
<div class="pt-5 h-full">
  <table mat-table [dataSource]="datasource" matSort #table>
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> NO.</th>
      <td mat-cell *matCellDef="let element"> {{ element.id }}</td>
    </ng-container>
    <ng-container matColumnDef="homeTeam">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> TEAM A</th>
      <td mat-cell *matCellDef="let element">
        <div class="flex items-center justify-center gap-2"><img class="teamBadge"
                                                                 *ngIf="element.homeTeamBadge != null"
                                                                 [src]='getSanitizedImageWithName(element.homeTeamBadge)'
                                                                 style="margin-right: 10px"> {{ element.homeTeam }}
          <button mat-raised-button color="primary"
                  class="btn-6 mr-3" (click)="downloadOnArrivialTeamSheet(element.id, true)"
                  matTooltip="Download On Arrival Team Sheet">
            <mat-icon
              class="btn-mat-icon-darkblue">attachment
            </mat-icon>
          </button>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="visitingTeam">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> TEAM B</th>
      <td mat-cell *matCellDef="let element">
        <div class="flex items-center justify-center gap-2"><img class="teamBadge"
                                                                 *ngIf="element.visitingTeamBadge != null"
                                                                 [src]='getSanitizedImageWithName(element.visitingTeamBadge)'
                                                                 style="margin-right: 10px">
          {{ element.visitingTeam }}
          <button mat-raised-button color="primary" class="btn-6 mr-3"
                  (click)="downloadOnArrivialTeamSheet(element.id, false)"
                  matTooltip="Download On Arrival Team Sheet">
            <mat-icon
              class="btn-mat-icon-darkblue">attachment
            </mat-icon>
          </button>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="matchDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> MATCH DATE</th>
      <td mat-cell *matCellDef="let element"> {{ element.matchDate | date:'MMM d, y HH:mm:ss' }}</td>
    </ng-container>
    <ng-container matColumnDef="tournament">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> TOURNAMENT</th>
      <td mat-cell *matCellDef="let element"> {{ element.tournament.title }}</td>
    </ng-container>
    <ng-container matColumnDef="country">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> COUNTRY</th>
      <td mat-cell *matCellDef="let element"> {{ element.country }}</td>
    </ng-container>
    <ng-container matColumnDef="masterCode">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> #6 CODE</th>
      <td mat-cell *matCellDef="let element"> {{ element.masterPin }}</td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef> ACTIONS</th>
      <td mat-cell *matCellDef="let element">
        <button mat-raised-button color="primary" class="btn-3 mr-3" (click)="goToMatch(element.id)"
                matTooltip="View match">
          <mat-icon class="btn-mat-icon-darkblue">visibility</mat-icon>
        </button>
        <button mat-raised-button color="primary" class="btn-3 mr-3" (click)="resetMatch(element.id)"
                matTooltip="Reset match" *ngIf="portalUserService.isAdmin()">
          <mat-icon class="btn-mat-icon-darkblue">refresh</mat-icon>
        </button>
        <button mat-raised-button color="primary" class="btn-3 mr-3" (click)="deleteMatch(element.id)"
                matTooltip="Delete match" *ngIf="portalUserService.isAdmin()">
          <mat-icon class="btn-mat-icon-darkblue">delete</mat-icon>
        </button>
        <button mat-raised-button color="primary" class="btn-3 mr-3" (click)="syncMatch(element.rimsId)"
                [disabled]="element.rimsId === null" matTooltip="Sync match with RIMS">
          <mat-icon
            class="btn-mat-icon-darkblue">sync
          </mat-icon>
        </button>
        <button mat-raised-button color="primary" class="btn-3"
                (click)="showHideFromApp(element.id, !element.hiddenInApp)"
                matTooltip="Show / Hide in App" *ngIf="portalUserService.isAdmin()">
          <mat-icon *ngIf="element.hiddenInApp == true" class="btn-mat-icon-darkblue">publish</mat-icon>
          <mat-icon *ngIf="element.hiddenInApp == false" class="btn-mat-icon-darkblue">stop</mat-icon>
        </button>
        <button mat-raised-button color="primary" class="btn-3"
                (click)="downloadMatchReport(element.id)"
                matTooltip="Download Match Report">
          <mat-icon
            class="btn-mat-icon-darkblue">attachment
          </mat-icon>
        </button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator #paginator [pageSizeOptions]="[50,100,150]" [pageSize]="pageSize" [length]="totalElements"
                 (page)="nextPage($event)" showFirstLastButtons></mat-paginator>
</div>
