<form [formGroup]="matchDetailsSubForm">
  <div class="grid grid-cols-2 gap-3 pt-5">
    <mat-form-field appearance="outline">
      <mat-label>Team A</mat-label>
      <input matInput placeholder="Team A" formControlName="homeTeam" required>
      <ngx-colors matSuffix ngx-colors-trigger formControlName="homeTeamColor" class="color-picker"></ngx-colors>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Team A Flag</mat-label>
      <mat-select [(value)]="selectedHomeTeamFlag" formControlName="homeTeamBadge">
        <mat-select-trigger>
          <div class="flex flex-row items-center">
            <div><img width="15" height="15" [src]='getSanitizedImageWithName(selectedHomeTeamFlag)'
                      style="margin-right: 10px"></div>
            <div>{{ (selectedHomeTeamFlag || '').replace('.png', '') }}</div>
          </div>
        </mat-select-trigger>
        <mat-option *ngFor="let flag of flags" [value]="flag.name">
          <div class="flex flex-row items-center">
            <div><img width="15" height="15" [src]='getSanitizedImage(flag.content)'
                      style="margin-right: 10px">
            </div>
            <div>{{ flag.name.replace(".png", "") }}</div>
          </div>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Team B</mat-label>
      <input matInput placeholder="Team B" formControlName="visitingTeam" required>
      <ngx-colors matSuffix ngx-colors-trigger formControlName="visitingTeamColor" class="pr-3"></ngx-colors>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Team B Flag</mat-label>
      <mat-select [(value)]="selectedVisitingTeamFlag" formControlName="visitingTeamBadge">
        <mat-select-trigger>
          <div class="flex flex-row items-center">
            <div><img width="15" height="15" [src]='getSanitizedImageWithName(selectedVisitingTeamFlag)'
                      style="margin-right: 10px"></div>
            <div>{{ (selectedVisitingTeamFlag || '').replace('.png', '') }}</div>
          </div>
        </mat-select-trigger>
        <mat-option *ngFor="let flag of flags" [value]="flag.name">
          <div class="flex flex-row items-center">
            <div><img width="15" height="15" [src]='getSanitizedImage(flag.content)'
                      style="margin-right: 10px">
            </div>
            <div>{{ flag.name.replace(".png", "") }}</div>
          </div>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Tournament</mat-label>
      <mat-select formControlName="tournament" required>
        <mat-option *ngFor="let tournament of tournaments" [value]="tournament">
          {{ tournament.title }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Venue</mat-label>
      <input matInput placeholder="Venue" formControlName="venue" required>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Country</mat-label>
      <input matInput placeholder="Country" formControlName="country" required>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Conditions</mat-label>
      <input matInput placeholder="Conditions" formControlName="conditions">
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Crowd Size</mat-label>
      <input type="number" matInput placeholder="Crowd Size" formControlName="crowdSize">
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Match date</mat-label>
      <input matInput [matDatepicker]="datepicker" formControlName="matchDate">
      <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
      <mat-datepicker #datepicker></mat-datepicker>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Match time</mat-label>
      <input matInput [ngxTimepicker]="picker" [format]="24" formControlName="matchTime">
      <ngx-material-timepicker #picker [theme]="darkTheme" [cancelBtnTmpl]="cancelBtn"
                               [confirmBtnTmpl]="confirmBtn"></ngx-material-timepicker>
      <ng-template #cancelBtn>
        <button mat-stroked-button color="accent"
                style="margin-right: 10px; background-color: white">CANCEL
        </button>
      </ng-template>
      <ng-template #confirmBtn>
        <button mat-stroked-button color="accent" style="background-color: white">CONFIRM</button>
      </ng-template>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Match Type</mat-label>
      <mat-select formControlName="matchType" required>
        <mat-option *ngFor="let matchType of matchTypes" [value]="matchType.value">
          {{ matchType.viewValue }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Team Size</mat-label>
      <input type="number" matInput placeholder="Team Size" formControlName="teamSize">
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Substitution Rules</mat-label>
      <mat-select formControlName="substitutionRule" required>
        <mat-option *ngFor="let subRule of subRules" [value]="subRule.value">
          {{ subRule.viewValue }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Number of Subs Allowed</mat-label>
      <input type="number" matInput placeholder="Number of Subs Allowed" formControlName="numberOfSubs">
    </mat-form-field>
  </div>
  <!-- <button mat-raised-button color="primary" class="btn">Save</button> -->
</form>
