import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MatchConfigurationDTO } from 'src/app/dtos/MatchConfigurationDTO';
import { MatchDTO } from 'src/app/dtos/MatchDTO';
import { PlayerDTO } from 'src/app/dtos/PlayerDTO';
import { TeamDTO } from 'src/app/dtos/TeamDTO';
import { Match } from 'src/app/interfaces/Match';
import { MatchSettings } from 'src/app/interfaces/MatchSettings';
import { Officials } from 'src/app/interfaces/Officials';
import { MatchService } from 'src/app/services/MatchService';

@Component({
  selector: 'app-new-match',
  templateUrl: './new-match.component.html',
  styleUrls: ['./new-match.component.css']
})
export class NewMatchComponent {
  isLinear = true;
  firstFormGroup!: FormGroup;
  secondFormGroup!: FormGroup;
  homeTeamFormGroup!: FormGroup;
  visitingTeamFormGroup!: FormGroup;
  gameSettingsFromGroup!: FormGroup;
  homeTeamPlayers!: FormArray;
  awayTeamPlayers!: FormArray;

  matchDetails?: Match;
  officials?: Officials;
  homeTeam?: PlayerDTO[] = [];
  visitingTeam?: PlayerDTO[] = [];
  matchSettings?: MatchSettings;
  onFieldPlayers: number = 15;

  constructor(
    private _formBuilder: FormBuilder,
    private matchService: MatchService,
    private router: Router,
    public datepipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.firstFormGroup = this._formBuilder.group({
    });
    this.secondFormGroup = this._formBuilder.group({
    });
    this.homeTeamFormGroup = this._formBuilder.group({
    });
    this.visitingTeamFormGroup = this._formBuilder.group({
    });
    this.gameSettingsFromGroup = this._formBuilder.group({
    });
    this.homeTeamPlayers = this._formBuilder.array([]);
    this.awayTeamPlayers = this._formBuilder.array([]);
  }

  matchDetailsSubmit(): void {
    console.log("going to save match details");

    console.log(this.firstFormGroup?.value)

    this.matchDetails = this.firstFormGroup?.get("matchDetails")?.value;
    console.log("match date " + this.datepipe.transform(this.matchDetails?.matchDate, "yyyy-MM-dd"));

    for (let i = 0; i < this.matchDetails?.teamSize!; i++) {
      console.log("adding home team player " + i);
      this.homeTeamPlayers?.push(this._formBuilder.group({
        playerName: [''],
        looseHead: [false],
        hooker: [false],
        tightHead: [false],
        u19: [false],
        position: [i+1],
        jerseyNumber: [i+1]
      }));

      this.awayTeamPlayers?.push(this._formBuilder.group({
        playerName: [''],
        looseHead: [false],
        hooker: [false],
        tightHead: [false],
        u19: [false],
        position: [i+1],
        jerseyNumber: [i+1]
      }))
    }

    if (this.matchDetails?.matchType == "SEVEN_A_SIDE") {
      this.onFieldPlayers = 7;
    }

    console.log(this.matchDetails?.matchTime)
    let time = new Date (this.datepipe.transform(this.matchDetails?.matchDate, "yyyy-MM-dd") + ' ' + this.matchDetails?.matchTime);
    console.log(time);
    this.matchDetails?.matchDate.setTime(time.getTime())
    console.log(this.matchDetails?.matchDate);
  }

  officialsSummit(): void {
    console.log(this.secondFormGroup?.value);
    this.officials = this.secondFormGroup?.get("officials")?.value;
    if (this.officials?.referee == '') {
      this.officials.referee = 'Official 1';
    }
    if (this.officials?.assistantReferee1 == '') {
      this.officials.assistantReferee1 = 'Official 2';
    }
    if (this.officials?.assistantReferee2 == '') {
      this.officials.assistantReferee2 = 'Official 3';
    }
    if (this.officials?.matchOfficial4 == '') {
      this.officials.matchOfficial4 = 'Official 4';
    }
    if (this.officials?.matchOfficial5 == '') {
      this.officials.matchOfficial5 = 'Official 5';
    }
    if (this.officials?.matchOfficial6 == '') {
      this.officials.matchOfficial6 = 'Official 6';
    }
    if (this.officials?.tmo == '') {
      this.officials.tmo = 'Official 7';
    }
  }

  homeTeamSubmit(): void {
    this.homeTeamPlayers?.controls.forEach((element, index) => {
      console.log(element.get("playerName")?.value)
      let player: PlayerDTO = {
        caption: false,
        fullName: element.get("playerName")?.value == '' ? 'Player ' + element.get("position")?.value : element.get("playerName")?.value,
        hooker: element.get("hooker")?.value,
        jerseyNumber: element.get("jerseyNumber")?.value,
        looseHead: element.get("looseHead")?.value,
        onField: element.get("position")?.value <= this.onFieldPlayers ? true : false,
        position: element.get("position")?.value,
        frontRowPosition: element.get("position")?.value <= 3 ? element.get("position")?.value : null,
        prop: element.get("looseHead")?.value == true || element.get("hooker")?.value == true || element.get("tightHead")?.value == true,
        startingFifteen: element.get("position")?.value <= this.onFieldPlayers ? true : false,
        tightHead: element.get("tightHead")?.value,
        u19: element.get("u19")?.value
      }
      this.homeTeam?.push(player);
    })
    console.log(this.homeTeam)
  }

  visitingTeamSubmit(): void {
    this.awayTeamPlayers?.controls.forEach((element, index) => {
      let player: PlayerDTO = {
        caption: false,
        fullName: element.get("playerName")?.value == '' ? 'Player ' + element.get("position")?.value : element.get("playerName")?.value,
        hooker: element.get("hooker")?.value,
        jerseyNumber: element.get("jerseyNumber")?.value,
        looseHead: element.get("looseHead")?.value,
        onField: element.get("position")?.value <= this.onFieldPlayers ? true : false,
        position: element.get("position")?.value,
        frontRowPosition: element.get("position")?.value <= 3 ? element.get("position")?.value : null,
        prop: element.get("looseHead")?.value == true || element.get("hooker")?.value == true || element.get("tightHead")?.value == true,
        startingFifteen: element.get("position")?.value <= this.onFieldPlayers ? true : false,
        tightHead: element.get("tightHead")?.value,
        u19: element.get("u19")?.value
      }
      this.visitingTeam?.push(player);
    })
    console.log(this.visitingTeam)
  }

  matchSettingsSubmit(): void {
    console.log(this.gameSettingsFromGroup?.value)
    this.matchSettings = this.gameSettingsFromGroup?.get("matchSettings")?.value;
    console.log(this.firstFormGroup?.get("matchDetails")?.get("numberOfSubs")?.value)
    this.matchSettings!.numberOfSubstitutions = this.firstFormGroup?.get("matchDetails")?.get("numberOfSubs")?.value
    console.log(this.matchSettings)
  }

  createMatch(): void {
    console.log(this.matchDetails)
    console.log(this.officials)
    console.log(this.homeTeam)
    console.log(this.visitingTeam)
    console.log(this.matchSettings)
    if (this.matchDetails != null && this.officials != null && this.homeTeam != null && this.visitingTeam != null && this.matchSettings != null) {
      console.log("Going to create a match now......");
      this.matchService.createMatch(this.matchDetails, this.officials).subscribe((data: MatchDTO) => {
        console.log("create match response: " + data);
        if (data.id != null && data.id > 0) {
          this.matchService.addTeamPlayers(data.teamOne.id!, this.homeTeam!).subscribe((data: TeamDTO) => {
            console.log("home team created: " + data);
          });
          this.matchService.addTeamPlayers(data.teamTwo.id!, this.visitingTeam!).subscribe((data: TeamDTO) => {
            console.log("visiting team created: " + data);
          });
          this.matchService.addMatchSettings(data.id!, this.matchSettings!).subscribe((data: MatchConfigurationDTO) => {
            console.log("match settings added");
          });
          this.router.navigate(['matches']);
        }
      });
    }
  }
}
