<mat-accordion class="example-headers-align p-4">
  <mat-expansion-panel expanded="true" class="p-4 m-4">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Sactions
      </mat-panel-title>
      <mat-panel-description>
        Match sanction reports
      </mat-panel-description>
      <button mat-raised-button color="accent" style="margin-right: 10px" [routerLink]="['/citing-report-details/create/', match!.id]">
        Add Report
      </button>
    </mat-expansion-panel-header>

    <table mat-table [dataSource]="datasource" matSort #table>
      <ng-container matColumnDef="team">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> TEAM</th>
        <td mat-cell *matCellDef="let element">
          <div *ngIf="element.teamId != null" class="flex items-center justify-center gap-2">
            <img class="teamBadge"
                 [src]='getSanitizedImageWithName(getTeamBadge(element.teamId))'
                 style="margin-right: 10px">
            {{ getTeamName(element.teamId) }}
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="player">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> PLAYER</th>
        <td mat-cell *matCellDef="let element">
          <span
            *ngIf="element.isAdditionalReport === true || (element.isCitingReport === true && element.sanctionedPlayerId == null)">{{ element.sanctionedNameOnAdditional }}</span>
          <span
            *ngIf="element.isAdditionalReport === false || (element.isCitingReport === true && element.sanctionedPlayerId != null)">{{ element.jerseyNumber }}
            -
            {{ getPlayerName(element.sanctionedPlayerId, element.teamId) }}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="isAdditionalReport">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> ADDITIONAL REPORT</th>
        <td mat-cell *matCellDef="let element">
          <div *ngIf="element.isAdditionalReport">
            <mat-icon>check</mat-icon>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="reportType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> REPORT</th>
        <td mat-cell *matCellDef="let element">{{ element.reportType }}</td>
      </ng-container>
      <ng-container matColumnDef="sanction">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> SANCTION</th>
        <td mat-cell *matCellDef="let element">
          <div class="flex items-center justify-center gap-2"><img class="teamBadge"
                                                                   *ngIf="element.cardType != null"
                                                                   [src]='getSanitizedCardTypeWithName(element.cardType)'
                                                                   style="margin-right: 10px">
            {{ element.cardType != null ? (element.cardType.replaceAll("_", " ")) : '' }}
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> STATUS</th>
        <td mat-cell *matCellDef="let element">
          <div class="flex items-center justify-center gap-2">
            {{ element.sanctionEmailSent ? 'EMAIL SENT' : (element.isReadyToEmail ? 'EMAIL PENDING' : 'SAVED') }}
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> ACTIONS</th>
        <td mat-cell *matCellDef="let element">
          <button mat-raised-button color="primary" class="btn-6 mr-3"
                  (click)="downloadSanctionReport(element.id, element.sanctionedNameOnAdditional == null ? getPlayerName(element.sanctionedPlayerId, element.teamId) : element.sanctionedNameOnAdditional)"
                  matTooltip="Download Sanction Report">
            <mat-icon class="btn-mat-icon-darkblue">attachment</mat-icon>
          </button>
          <button mat-raised-button *ngIf="element.isCitingReport" color="accent"
                  [routerLink]="['/citing-report-details/update/', element.id]">Edit
            Report
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

  </mat-expansion-panel>
</mat-accordion>
