import {Component, OnInit} from '@angular/core';
import {environment} from 'src/environments/environment';
import {MsalService} from "@azure/msal-angular";
import {AuthenticationResult} from "@azure/msal-browser";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'rugby-player-movement-portal';
  prod = environment.production;

  constructor(private msalService: MsalService) {

  }

  ngOnInit(): void {

    (async () => {
      try {
        await this.msalService.instance.initialize();
        this.msalService.instance.handleRedirectPromise().then(
          res => {
            if (res != null && res.account != null) {
              this.msalService.instance.setActiveAccount(res.account)
            }
          }
        );
      } catch (error) {
        console.error('MSAL initialization failed', error);
      }
    })();
  }

  isLoggedIn(): boolean {
    return this.msalService.instance.getActiveAccount() != null
  }

  login() {
    this.msalService.loginRedirect();
  }

  logout() {
    this.msalService.logout();
  }
}
