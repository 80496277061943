<div class="w-full flex justify-center grid grid-cols-1 gap-4 mat-typography">
    <!-- <div class="items-center">
      <mat-accordion class="example-headers-align" multi>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title style="color: #093F8B">
              Match Events
            </mat-panel-title>
            <mat-panel-description>
  
              <mat-icon>filter_list</mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="space-x-4">
            <button mat-raised-button (click)="exporter.exportTable('xlsx', {fileName:'Match Report', sheet: 'sheet_name'})">Download as Excel</button>
            <button mat-raised-button (click)="exporter.exportTable('csv', {fileName:'Match Report'})">Download as Csv</button>
            <button mat-raised-button (click)="exporter.exportTable('json', {fileName:'Match Report'})">Download as Json</button>
            <button mat-raised-button (click)="exporter.exportTable('txt', {fileName:'Match Report'})">Download as Txt</button>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div> -->
    <div class="space-x-4">

    </div>
    <div>
        <table mat-table [dataSource]="datasource" class="mat-elevation-z8" #table>
            <ng-container matColumnDef="time">
                <th mat-header-cell *matHeaderCellDef style="background-color: #093F8B !important; color: white"> Time</th>
                <td mat-cell *matCellDef="let element"> {{element.time}} </td>
            </ng-container>
            <ng-container matColumnDef="team">
                <th mat-header-cell *matHeaderCellDef style="background-color: #093F8B; color: white"> Team</th>
                <td mat-cell *matCellDef="let element">
                    <ng-container *ngIf="element.team">{{element.team.name}}</ng-container>
                </td>
            </ng-container>
            <ng-container matColumnDef="event">
                <th mat-header-cell *matHeaderCellDef style="background-color: #093F8B; color: white"> Event</th>
                <td mat-cell *matCellDef="let element"> {{element.eventType}} </td>
            </ng-container>
            <ng-container matColumnDef="playerOn">
                <th mat-header-cell *matHeaderCellDef style="background-color: #093F8B; color: white"> Player On</th>
                <td mat-cell *matCellDef="let element">
                    <ng-container *ngIf="element.playerOn">{{element.playerOn.jerseyNumber + " - " +
                        element.playerOn.fullName}}</ng-container>
                    <ng-container *ngIf="element.playerScored">{{element.playerScored.jerseyNumber + " - " +
                        element.playerScored.fullName}}</ng-container>
                </td>
            </ng-container>
            <ng-container matColumnDef="playerOff">
                <th mat-header-cell *matHeaderCellDef style="background-color: #093F8B; color: white"> Player Off</th>
                <td mat-cell *matCellDef="let element">
                    <ng-container *ngIf="element.playerOff">{{element.playerOff.jerseyNumber + " - " +
                        element.playerOff.fullName}}</ng-container>
                </td>
            </ng-container>
            <ng-container matColumnDef="homeScore">
                <th mat-header-cell *matHeaderCellDef style="background-color: #093F8B; color: white"> Home Score</th>
                <td mat-cell *matCellDef="let element"> {{element.homeScore}} </td>
            </ng-container>
            <ng-container matColumnDef="awayScore">
                <th mat-header-cell *matHeaderCellDef style="background-color: #093F8B; color: white"> Away Score</th>
                <td mat-cell *matCellDef="let element"> {{element.visitingScore}} </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <div>

        </div>
    </div>
</div>