import { ChangeDetectorRef, Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { HistoryDTO } from 'src/app/dtos/HistoryDTO';
import { MatchService } from 'src/app/services/MatchService';

@Component({
  selector: 'app-match-timeline',
  templateUrl: './match-timeline.component.html',
  styleUrls: ['./match-timeline.component.css']
})
export class MatchTimelineComponent {
  datasource: any;
  matchEvents: HistoryDTO[] = [];
  displayedColumns: string[] = ['time', 'team', 'event', 'playerOn', 'playerOff', 'homeScore', 'awayScore'];

  constructor(private matchService: MatchService,
    private router: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private changeDetectorRefs: ChangeDetectorRef) {

  }

  ngOnInit(): void {
    const routeParams = this.router.snapshot.paramMap;
    if (routeParams.get("id") != null) {
      var matchId = Number(routeParams.get("id"));
      this.loadEvents(matchId);
    }
  }

  loadEvents(id: number) {
    this.matchService.getMatchEvents(id)
      .subscribe((data: HistoryDTO) => {
          console.log(data);
          localStorage.setItem('matchEvents', JSON.stringify(data));
          this.matchEvents = JSON.parse(localStorage.getItem("matchEvents")!);
          this.datasource = new MatTableDataSource(this.matchEvents);
          this.changeDetectorRefs.detectChanges();
        }, (error => {
          console.error("Error calling service: " + JSON.stringify(error));
          this.handleError(error, "Unable to get match events");
        })
      );

    
  }

  handleError(error: any, errorMessage: string): void {
    this._snackBar.open(errorMessage, 'OK', {
      horizontalPosition: "center",
      verticalPosition: "top",
    });
  }
}
